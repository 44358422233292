.form_popup {
    position: fixed;
    inset: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    font-size: 30px;
    text-align: center;
}

.form_popup-modal {
    width: 800px;
    padding: 50px 20px;
    border-radius: 5px;
    background: #070707;
    border: 1px solid #303136;
}

.form_popup-close {
    position: absolute;
    right: 20px;
    top: 17px;
    width: 25px;
    height: 25px;
}

.mails-input-btn {
    width: fit-content;
}

.mails-input-btn:hover::after {
    background-image: url('../../../../img/btn-arr_black.svg');
}

.mails-input-btn::after {
    content: '';
    position: absolute;
    right: 54px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url('../../../../img/btn-arr.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.mails-btn {
    box-shadow: none;
    background-color: #1A9317;
}

.mails-form_input-block {
    margin-bottom: 20px;
}

.form-error {
    color: #FF0000;
    font-size: 13px;
}

.mails_form--input {
    max-width: 467px;
    background: #0E0E0E;
    border-radius: 5px;
}

.mails-checkbox_block {
    display: flex;
    margin-bottom: 40px;
    max-width: 420px;
}

.mails-checkbox_block-mbn {
    margin-bottom: 10px;
}

.mails-checkbox {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-right: 12px;
    padding: 0;
    background: #0E0E0E;
    border: unset;
    position: relative;
    cursor: pointer;
}

.mails-checkbox_label a {
    color: #1A9317;
}

.mails-checkbox:after,
.mails-checkbox:before {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 4px;
    width: 0;
    height: 2px;
    background: #D92499;
    border-radius: 3px;
    transform-origin: center left;
    transition: .2s ease;
}

.mails-wrap .mails-checkbox:after,
.mails-wrap .mails-checkbox:before {
    background: #1A9317;
}

.mails-checkbox:before {
    transform: rotate(57deg);
    left: 7px;
    bottom: 13px;
    transition-delay: .18s;
}

.mails-checkbox:after {
    transform: rotate(-58deg);
    transition-delay: 0ms;
}

.mails-checkbox:checked {
    background: #fff;
    box-shadow: inset 0 0 0 1px #fff;
    color: #fff;
}

.mails-checkbox:checked:before {
    width: 11px;
    transition-delay: 0ms;
}

.mails-checkbox:checked:after {
    width: 15px;
    transition-delay: .18s;
}

@media screen and (max-width: 992px) {
    .form_popup-modal {
        width: 500px;
    }

    .try-form {
        grid-template-columns: [col] auto;
        grid-template-rows: [row] auto [row] auto [row] auto;
    }

    .try-btn {
        grid-column: col;
        grid-row: row 3;
        margin-top: 12px;
    }

    .try-form_input-block {
        grid-column: col;
        grid-row: row 1;
        margin-right: 0;
    }

    .try-checkbox_block {
        grid-column: col;
        grid-row: row 2;
    }
}

@media screen and (max-width: 768px) {
    .form_popup-modal {
        width: 320px;
        padding: 44px 20px 34px;
    }

    .form_popup-close {
        right: 15px;
        top: 15px;
        width: 20px;
        height: 20px;
    }
}