.lab-descr_title {
    margin-left: 15px;
    position: relative;
    margin-bottom: 30px;
}

.lab-descr_title::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    width: 3px;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(176deg, #FFC130 0%, #EF6F96 100%);
}

.lab-desc_content_block {
    display: flex;
}

.lab-descr_title {
    font-size: 36px;
}

.lab-desc_content {
    margin-right: 30px;
}

.lab-desc_img-block img {
    max-width: 706px;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.lab-desc_content {
    color: #CBCBCB;
}

.lab-desc_content li span {
    font-weight: 700;
    color: #fff;
}

.lab-desc_content p {
    margin-bottom: 20px;
}

.lab-desc_content li {
    margin-bottom: 20px;
}

.lab-desc_btn {
    margin-top: 40px;
}

@media screen and (max-width: 1450px) {
    .lab-desc_img-block img {
        max-width: 586px;
    }
}

@media screen and (max-width: 1200px) {
    .lab-desc_content_block {
        flex-direction: column;
    }

    .lab-descr_title {
        margin-bottom: 10px;
    }

    .lab-desc_img-block img {
        max-width: unset;
        width: 100%;
        height: 400px;
    }

    .lab-desc_btn {
        margin-top: 50px;
    }

    .lab-descr_title {
        font-size: 26px;
    }
}

@media screen and (max-width: 992px) {
    .lab-desc_img-block img {
        height: 300px;
    }

    .lab-descr_title b {
        max-width: 180px;
        width: 100%;
        display: block;
    }
}

@media screen and (max-width: 576px) {
    .lab-desc_img-block img {
        height: auto;
        object-position: center;
        transform: scale(1.1);
    }

    .container-labs.container_padding.lab-descr {
        padding-bottom: 180px;
    }
}