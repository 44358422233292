.faq-accordion {
    display: flex;
    flex-direction: column;
}

.faq {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.faq-accordion {
    max-width: 680px;
    width: 100%;
}

.faq-accordion:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 992px) {
    .faq {
        flex-direction: column;
    }

    .faq-title {
        margin-bottom: 30px;
    }

    .faq-accordion {
        max-width: unset;
    }
}

@media screen and (max-width: 768px) {}