@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;600;700;900&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  word-break: break-word;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
}

body {
  position: relative;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  background-color: #070707;
  color: #fff;
  font-family: 'Heebo', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

main {
  flex: 1 0 auto;
  overflow-x: clip;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

img {
  max-width: 100%;
  border-style: none;
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

svg {
  display: block;
  width: 100%;
}

ul li,
ol li {
  list-style-type: none;
}

label {
  cursor: pointer;
}

button {
  cursor: pointer;
}

iframe {
  border: none;
  outline: none;
}

.main-color {
  color: #008294;
}

.subcolor-1 {
  color: #CBCBCB;
}

.white {
  color: #fff;
}

input,
textarea {
  background: none;
  outline: none;
  border: 0;
  margin: 0;
  padding: 12px 16px;
  color: #fff;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: .625px;
  text-overflow: ellipsis;
  resize: none;
  box-shadow: inset 0 0 0 1px #3F3F3F;
  background: transparent;
  border-radius: 4px;
  transition: color .2s ease, box-shadow .2s ease;
}

input.invalid,
textarea.invalid {
  border-color: #cd1010;
}

input:focus,
textarea:focus {
  outline: none;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #CBCBCB;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #CBCBCB;
}

input::placeholder,
textarea::placeholder {
  color: #CBCBCB;
}

.text-colorful {
  color: #D92499;
}
div {
    position: relative;
}

.container {
    max-width: 1400px;
    margin: 5rem auto;
    padding: 0;
    width: 100%;
}

.container.container_padding {
    margin: 0 auto;
    padding: 80px 0;
}

.container-labs {
    max-width: 1400px;
    margin: 160px auto;
    padding: 0;
    width: 100%;
}

.container-labs.container_padding {
    margin: 0 auto;
    padding: 160px 0;
}

.container--full {
    margin: 100px auto;
}

h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 1.2;
    color: #fff;
    margin-bottom: 10px;
}

h2 {
    font-size: 28px;
    line-height: 1.2;
    color: #fff;
    font-weight: 400;
}

h3 {
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    color: #fff;
}

.p1 {
    font-size: 16px;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #D92499;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    padding: 0 73px 0 59px;
    font-weight: 300;
    font-size: 18px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: .25s ease;
    width: fit-content;
    height: 49px;
    position: relative;
}

.button::after {
    content: '';
    position: absolute;
    right: 55px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url(/static/media/btn-arr.6b604341.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.button:hover {
    background: #fff;
    color: #070707;
}

.button:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.button.lab-btn {
    background: linear-gradient(176deg, #FFC130 0%, #EF6F96 100%);
    color: #070707;
}

.button.lab-btn:hover {
    background: linear-gradient(176deg, #fff 0%, #fff 100%);
}

.button.lab-btn::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

iframe {
    border: none;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: #070707;
}

::-webkit-scrollbar-thumb {
    background-color: #D92499;
    border-radius: 15px;
}

@media screen and (max-width: 1450px) {
    .container-labs {
        max-width: 1100px;
    }

    .container {
        max-width: 1100px;
        padding: 0;
    }

    .container.container_padding {
        padding: 80px 0;
    }

    h1 {
        font-size: 52px;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 920px;
    }

    .container-labs {
        max-width: 920px;
    }

    h1 {
        font-size: 46px;
    }
}

@media screen and (max-width: 992px) {
    .container {
        max-width: 680px;
        padding: 0 10px;
    }

    .container-labs {
        max-width: 680px;
        padding: 0 10px;
    }

    h1 {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 500px;
    }

    .container-labs {
        max-width: 500px;
    }

    h1 {
        font-size: 48px;
    }

}

@media screen and (max-width: 576px) {
    .container {
        max-width: 100%;
    }

    .container.container_padding {
        padding: 70px 0;
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px) !important;
    }

    .container-labs {
        max-width: 100%;
        margin: 100px auto;
    }

    .container-labs.container_padding {
        padding: 100px 0;
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px) !important;
    }

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 18px;
    }
}

@media screen and (max-width: 420px) {
    h1 {
        font-size: 35px;
    }
}

@media screen and (max-width: 370px) {
    h1 {
        font-size: 33px;
    }
}
.mails-wrap {
    overflow: hidden;
    position: relative;
}

.mails-design {
    position: absolute;
    top: 50%;
    right: -100px;
    -webkit-transform: translateY(-32%);
            transform: translateY(-32%);
    z-index: -1;
}

.mails-wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 50%;
    width: 500px;
    height: 500px;
    background: #1A9317;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0) translate(0, 90%);
            transform: translate3d(0, 0, 0) translate(0, 90%);
}

.mails-wrap::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    -webkit-transform: translate(0, -50%) translate3d(0, 0, 0);
            transform: translate(0, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #1A9317;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
}

.mails-title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 36px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 10px;
}

.mails-title span {
    color: #1A9317;
}

.mails-title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #1A9317;
    border-radius: 15px;
}

.mails-desc {
    margin-bottom: 30px;
}

.mails-form_label {
    margin-bottom: 5px;
}

.mails-join-beta {
    position: absolute;
    top: 50%;
    right: 17%;
    -webkit-transform: translateY(-50%) rotate(15deg);
            transform: translateY(-50%) rotate(15deg);
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.mails-checkbox_label {
    line-height: 1.1;
}

@media screen and (max-width: 1600px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 360px;
        height: 360px;
        -webkit-filter: blur(250px);
                filter: blur(250px);
    }

    .mails-join-beta {
        width: 250px;
        height: 250px;
        right: 370px;
    }
}

@media screen and (max-width: 1450px) {
    .mails-join-beta {
        right: 100px;
    }
}

@media screen and (max-width: 1200px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 290px;
        height: 290px;
        -webkit-filter: blur(170px);
                filter: blur(170px);
    }

    .mails-wrap::before {
        top: 29%;
    }

    .mails-wrap::after {
        bottom: 40%;
    }
}

@media screen and (max-width: 992px) {
    .mails-join-beta {
        width: 150px;
        height: 150px;
        right: unset;
        top: unset;
        bottom: -50px;
        left: 350px;
    }

    .mails-wrap::before,
    .mails-wrap::after {
        width: 260px;
        height: 260px;
        -webkit-filter: blur(160px);
                filter: blur(160px);
    }

    .mails-wrap::before {
        -webkit-transform: translate(0, -80%) translate3d(0, 0, 0);
                transform: translate(0, -80%) translate3d(0, 0, 0);
    }

    .mails-wrap::after {
        -webkit-transform: translate(0, 120%) translate3d(0, 0, 0);
                transform: translate(0, 120%) translate3d(0, 0, 0);
    }

    .mails-design {
        display: none;
    }
}

@media screen and (max-width: 768px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 210px;
        height: 260px;
        -webkit-filter: blur(140px);
                filter: blur(140px);
    }

    .mails-desc {
        max-width: 330px;
    }
}

@media screen and (max-width: 576px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 180px;
        height: 180px;
        -webkit-filter: blur(110px);
                filter: blur(110px);
    }

    .mails-join-beta {
        width: 100px;
        height: 100px;
        right: unset;
        top: unset;
        bottom: 0px;
        left: 250px;
    }
}
.form_popup {
    position: fixed;
    inset: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    font-size: 30px;
    text-align: center;
}

.form_popup-modal {
    width: 800px;
    padding: 50px 20px;
    border-radius: 5px;
    background: #070707;
    border: 1px solid #303136;
}

.form_popup-close {
    position: absolute;
    right: 20px;
    top: 17px;
    width: 25px;
    height: 25px;
}

.mails-input-btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.mails-input-btn:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.mails-input-btn::after {
    content: '';
    position: absolute;
    right: 54px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url(/static/media/btn-arr.6b604341.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.mails-btn {
    box-shadow: none;
    background-color: #1A9317;
}

.mails-form_input-block {
    margin-bottom: 20px;
}

.form-error {
    color: #FF0000;
    font-size: 13px;
}

.mails_form--input {
    max-width: 467px;
    background: #0E0E0E;
    border-radius: 5px;
}

.mails-checkbox_block {
    display: flex;
    margin-bottom: 40px;
    max-width: 420px;
}

.mails-checkbox_block-mbn {
    margin-bottom: 10px;
}

.mails-checkbox {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-right: 12px;
    padding: 0;
    background: #0E0E0E;
    border: unset;
    position: relative;
    cursor: pointer;
}

.mails-checkbox_label a {
    color: #1A9317;
}

.mails-checkbox:after,
.mails-checkbox:before {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 4px;
    width: 0;
    height: 2px;
    background: #D92499;
    border-radius: 3px;
    -webkit-transform-origin: center left;
            transform-origin: center left;
    transition: .2s ease;
}

.mails-wrap .mails-checkbox:after,
.mails-wrap .mails-checkbox:before {
    background: #1A9317;
}

.mails-checkbox:before {
    -webkit-transform: rotate(57deg);
            transform: rotate(57deg);
    left: 7px;
    bottom: 13px;
    transition-delay: .18s;
}

.mails-checkbox:after {
    -webkit-transform: rotate(-58deg);
            transform: rotate(-58deg);
    transition-delay: 0ms;
}

.mails-checkbox:checked {
    background: #fff;
    box-shadow: inset 0 0 0 1px #fff;
    color: #fff;
}

.mails-checkbox:checked:before {
    width: 11px;
    transition-delay: 0ms;
}

.mails-checkbox:checked:after {
    width: 15px;
    transition-delay: .18s;
}

@media screen and (max-width: 992px) {
    .form_popup-modal {
        width: 500px;
    }

    .try-form {
        grid-template-columns: [col] auto;
        grid-template-rows: [row] auto [row] auto [row] auto;
    }

    .try-btn {
        grid-column: col;
        grid-row: row 3;
        margin-top: 12px;
    }

    .try-form_input-block {
        grid-column: col;
        grid-row: row 1;
        margin-right: 0;
    }

    .try-checkbox_block {
        grid-column: col;
        grid-row: row 2;
    }
}

@media screen and (max-width: 768px) {
    .form_popup-modal {
        width: 320px;
        padding: 44px 20px 34px;
    }

    .form_popup-close {
        right: 15px;
        top: 15px;
        width: 20px;
        height: 20px;
    }
}
.faq-accordion {
    display: flex;
    flex-direction: column;
}

.faq {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.faq-accordion {
    max-width: 680px;
    width: 100%;
}

.faq-accordion:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 992px) {
    .faq {
        flex-direction: column;
    }

    .faq-title {
        margin-bottom: 30px;
    }

    .faq-accordion {
        max-width: unset;
    }
}

@media screen and (max-width: 768px) {}
.accordion-item {
    padding: 16px;
    margin-bottom: 16px;
    background: #1B1B1B;
    border-radius: 4px;
    color: #fff;
    text-align: left;
}

.accordion-item:last-child {
    margin-bottom: 0;
}

.accordion-desc {
    margin-top: 16px;
    white-space: pre-line;
    opacity: .8;
    line-height: 1.3;
}

.accordion-title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.accordion-item_head svg {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    min-width: 20px;
}

.accordion-item_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.categories {
    display: flex;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
}

.categories-head {
    margin-right: 50px;
    max-width: 323px;
    width: 100%;
}

.categories-title {
    margin-bottom: 30px;
}

.categories-content {
    display: flex;
    align-items: flex-start;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-wrap: wrap;
}

.categories-checkbox_label {
    text-transform: capitalize;
    cursor: default;
    font-weight: 300;
}

.categories-item {
    background: #272727;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 7px;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: .25s ease;
    cursor: default;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.main-form_block,
.main-categories {
    width: 45%;
    margin-left: 2%;
}

.main-form_block--inner {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.main-form {
    margin-right: 8px;
}

.categories-checkbox_block {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.categories-checkbox {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-right: 12px;
    padding: 0;
    background: #272727;
    border: unset;
    box-shadow: inset 0 0 0 1px #949aa4;
    position: relative;
    cursor: pointer;
}

.categories-checkbox:after,
.categories-checkbox:before {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 4px;
    width: 0;
    height: 2px;
    background: #D92499;
    border-radius: 3px;
    -webkit-transform-origin: center left;
            transform-origin: center left;
    transition: .2s ease;
}

.categories-checkbox:before {
    -webkit-transform: rotate(57deg);
            transform: rotate(57deg);
    left: 7px;
    bottom: 13px;
    transition-delay: .18s;
}

.categories-checkbox:after {
    -webkit-transform: rotate(-58deg);
            transform: rotate(-58deg);
    transition-delay: 0ms;
}

.categories-checkbox:checked {
    background: #fff;
    box-shadow: inset 0 0 0 1px #fff;
    color: #fff;
}

.categories-checkbox:checked:before {
    width: 11px;
    transition-delay: 0ms;
}

.categories-checkbox:checked:after {
    width: 15px;
    transition-delay: .18s;
}

.categories .popup_btn--join {
    /* background: #FFB800; */
    margin-top: 40px;
    /* color: #070707; */
}

/* .categories .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.categories .popup_btn--join:hover::after {
    background-image: url('../../../img/btn-arr_black.svg');
}

.categories .popup_btn--join::after {
    background-image: url('../../../img/btn-arr_black.svg');
} */

@media screen and (max-width: 1200px) {
    .categories {
        flex-direction: column;
    }

    .categories-head {
        margin-right: 0;
        max-width: unset;
        width: 100%;
        margin-bottom: 30px;
    }

    .categories-title {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .main-categories_title {
        font-size: 1rem;
        margin-bottom: 20px;
    }

    .categories-item {
        padding: 5px 14px;
    }
}
.popup-overlay {
    background: rgba(23, 22, 22, .5);
    -webkit-backdrop-filter: blur(2.5px);
            backdrop-filter: blur(2.5px);
}

.modal {
    width: 547px;
    height: auto;
    max-height: calc(100vh - 200px);
    padding: 40px;
    background-image: url(/static/media/popup-bg.cb941112.png);
    background-size: cover;
    background-position: center;
    overflow-y: scroll;
    padding: 40px;
}

.popup-content .close {
    display: block;
    position: absolute;
    right: 8px;
    top: 12px;
    z-index: 10;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.packages-content_body {
    padding: 45px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packages-btn {
    border: none;
    box-shadow: none;
}

.packages-form {
    width: 100%;
    margin-bottom: 30px;
}

.packages-form_block {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.packages-form_input {
    margin-top: 25px;
    color: #fff;
}

.packages-form_input:focus {
    border-color: #fff;
}

.packages-form_input:nth-child(1) {
    margin-top: 0;
}

.packages-checkbox_label {
    line-height: 1.1;
    max-width: 308px;
}

.packages-checkbox_label a {
    color: #D92499;
    line-height: 1.1;
}

.packages-checkbox_label a:hover {
    color: #fff;
}

.form-error {
    color: #ff0000;
    margin-top: 7px;
    line-height: 1;
}

.packages-btn_block {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.packages-btn_block::after {
    content: '';
    position: absolute;
    right: 35px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url(/static/media/btn-arr.6b604341.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.packages-btn_block:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.button.packages-btn.packages-btn_unactive {
    opacity: .3;
}

.button.packages-btn.packages-btn_unactive:hover {
    opacity: .3;
}

.packages-form_input.error {
    border-color: #ff0000;
}

.packages-sales {
    font-weight: 700;
    margin-bottom: 10px;
}

@media screen and (max-height: 900px) {
    .modal {
        max-height: calc(100vh - 70px);
    }
}

@media screen and (max-width: 1450px) {
    .packages-form {
        max-width: unset;
    }

    .packages-content_body {
        padding: 45px 35px;
    }
}

@media screen and (max-width: 1200px) {
    .modal {
        width: calc(100vw - 100px);
    }
}

@media screen and (max-width: 768px) {
    .packages-content_body {
        padding: 25px;
    }

    .modal {
        width: 100vw;
        max-height: calc(100vh - 70px);
        padding: 30px 20px;
    }
}
.packages-wrap_main .packages {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    justify-content: space-between;
}

.packages-wrap_main .packages-card {
    background: #1B1B1B;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.packages-wrap_main .packages-card:nth-child(1) {
    margin-right: 40px;
    max-width: 360px;
    width: 100%;
}

.packages-wrap_main .packages-card-wrap {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.packages-wrap_main .packages-card-wrap img {
    height: 234px;
    max-width: 380px;
    width: 100%;
    object-fit: cover;
}

.packages-wrap_main .packages-card-wrap .packages-card {
    border-radius: 0;
}

.packages-wrap_main .packages-btn {
    margin-top: auto;
}

.packages-wrap_main .packages-card .popup_btn--join {
    margin-top: auto;
}

.packages-wrap_main .packages-title {
    margin-bottom: 10px;
}

.packages-wrap_main .packages-desc {
    line-height: 1.1;
    margin-bottom: 20px;
    max-width: 300px;
}

.packages-wrap_main .packages-desc span {
    font-weight: 700;
    color: #fff;
}

@media screen and (max-width: 1450px) {
    .packages-wrap_main .packages-card-wrap img {
        height: 251px;
        max-width: 260px;
    }
}

@media screen and (max-width: 1200px) {
    .packages-wrap_main .packages-card-wrap img {
        height: 100%;
        max-width: unset;
        width: auto;
    }

    .packages-wrap_main .packages-card:nth-child(1) {
        max-width: unset;
        width: auto;
    }

    .packages-wrap_main .packages-card-wrap {
        width: auto;
    }
}

@media screen and (max-width: 992px) {
    .packages-wrap_main .packages {
        flex-direction: column;
    }

    .packages-wrap_main .packages-card:nth-child(1) {
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .packages-wrap_main .packages-card {
        background: transparent;
        padding: 0;
    }

    .packages-wrap_main .packages-card-wrap {
        flex-direction: column;
    }

    .packages-wrap_main .packages-card {
        background: #1B1B1B;
        border-radius: 5px;
        padding: 40px 12px 40px;
    }

    .packages-wrap_main .packages-card-wrap img {
        height: 170px;
        position: relative;
    }

    .packages-wrap_main .packages-card-wrap .packages-btn {
        margin-left: auto;
        margin-right: auto;
    }

    .packages-wrap_main .packages-card-wrap .packages-title {
        /* margin-top: 15px; */
        margin-bottom: 15px;
    }
}
.inspiration {
    display: flex;
    justify-content: space-between;
    /* border-top: 1px solid rgba(255, 255, 255, 0.15); */
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
}

.inspiration-gif {
    max-width: 600px;
    width: 100%;
    height: auto;
    object-fit: contain;
}

.inspiration-title {
    font-weight: 700;
    font-size: 46px;
    line-height: 123.5%;
}

.inspiration-desc {
    line-height: 1.1;
    margin-top: 10px;
}

.inspiration-head-wrap {
    max-width: 420px;
}

.inspiration-head {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 13px;
    position: relative;
}

.inspiration-head::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #D92499;
    border-radius: 15px;
}

@media screen and (max-width: 1450px) {
    .inspiration-title {
        font-size: 36px;
    }

    .inspiration-head {
        max-width: 280px;
    }
}

@media screen and (max-width: 1200px) {
    .inspiration-head-wrap {
        margin-right: 40px;
        max-width: 350px;
    }
}

@media screen and (max-width: 992px) {
    .inspiration {
        flex-direction: column;
    }

    .inspiration-head-wrap {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    .inspiration {
        overflow-x: hidden;
        margin-left: 0;
        margin-right: 0;
        padding-right: 10px;
        padding-left: 10px;
        max-width: 100% !important;
    }

    .inspiration-gif {
        max-width: unset;
    }

    .inspiration-head-wrap {
        max-width: 500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 576px) {
    .inspiration-gif {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    .inspiration-head-wrap {
        max-width: unset;
        margin-left: 10px;
        margin-right: 10px;
    }

    .inspiration-head-wrap {
        margin-left: 0;
        margin-right: 0;
    }

    .inspiration-head-wrap {
        margin-bottom: 40px;
        max-width: 370px;
    }

    .inspiration-title {
        font-size: 26px;
    }
}
.main-wrap {
    position: relative;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: clip;
}

.main-wrap::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 655px;
    height: 540px;
    background-image: url(/static/media/design.33519c7d.png);
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -2;
}

.main-wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -140px;
    width: 500px;
    height: 500px;
    background: rgba(217, 36, 153, 0.5);
    -webkit-filter: blur(300px);
            filter: blur(300px);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    padding-top: 50px;
}

.main-title span {
    margin-right: auto;
}

.main-item {
    width: 50%;
}

.main-desc {
    max-width: 570px;
    line-height: 1.1;
    font-weight: 300;
    font-size: 28px;
    /* margin-bottom: 50px; */
}

.main-wrap button.button.popup_btn--join {
    margin-top: 40px;
}

/* 
.main-wrap button.button.popup_btn--join {
    margin-top: 40px;
    background: #fff;
    color: #070707;
}

.main-wrap button.button.popup_btn--join:hover {
    background: #D92499;
    color: #fff;
}

.main-wrap button.button.popup_btn--join:hover::after {
    background-image: url('../../../img/btn-arr.svg');
}

.main-wrap button.button.popup_btn--join::after {
    background-image: url('../../../img/btn-arr_black.svg');
} */

.main-img {
    -webkit-transform: scale(1.1) translate(-90px, 62px);
            transform: scale(1.1) translate(-90px, 62px);
}

.main-content {
    z-index: 1;
}

.main-img_mob {
    display: none;
}

@media screen and (max-width: 1450px) {
    .main-wrap::before {
        width: 515px;
        height: 450px;
    }

    .main {
        padding-top: 50px;
    }

    .main-desc {
        max-width: 510px;
        font-size: 24px;
    }
}

@media screen and (max-width: 1200px) {
    .main-img {
        -webkit-transform: scale(1.1) translate(-40px, 82px);
                transform: scale(1.1) translate(-40px, 82px);
    }
}

@media screen and (max-width: 992px) {
    .main {
        flex-direction: column;
    }

    .main-item {
        width: 100%;
    }

    .main-img {
        -webkit-transform: unset;
                transform: unset;
    }

    .main-img_pc {
        display: none;
    }

    .main-img_block {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    .main-img_mob {
        order: 1;
        display: block;
    }

    .main-wrap::after {
        bottom: unset;
        top: 0;
    }

    .main-content {
        margin-bottom: 0;
        order: 2;
    }

    .main-wrap {
        min-height: unset;
        height: 100%;
    }
}

@media screen and (max-width: 576px) {
    .main-wrap::after {
        width: 310px;
        height: 310px;
    }

    .main-wrap::before {
        width: 385px;
        height: 410px;
        -webkit-transform: scale(1.2) translateX(80px);
                transform: scale(1.2) translateX(80px);
    }

    .main-desc {
        max-width: 320px;
        font-size: 22px;
    }
}
.sf {
    display: flex;
}

.sf-wrap {
    background: #000;
}

.sf-head {
    margin-right: 50px;
    max-width: 203px;
    width: 100%;
}

.sf-content {
    display: flex;
    flex-wrap: wrap;
}

.sf-logo:nth-child(4n) {
    padding-right: 0;
}

.sf-logo:nth-child(4n + 1) {
    padding-left: 0;
}

.sf-logo {
    width: calc(100% / 4);
    padding: 5px 42px;
    object-fit: contain;
    object-position: center;
}

.sf-content_mob {
    display: none;
}

.scrolling-images-container {
    display: none;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.scrolling-images-container::-webkit-scrollbar {
    width: 4px;
}

.scrolling-images-container::-webkit-scrollbar-track {
    background: transparent;
}

.scrolling-images-container::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
}

.scrolling-image-wrapper {
    flex: 0 0 115px;
    margin-right: 10px;
    margin-left: 10px;
}

.scrolling-image-wrapper img {
    width: 100%;
    height: auto;
    max-height: 53px;
}

@media screen and (max-width: 1450px) {
    .sf-logo {
        padding: 5px 20px;
    }
}

@media screen and (max-width: 1200px) {
    .sf-head {
        margin-right: 0;
        max-width: unset;
        width: 100%;
        margin-bottom: 30px;
    }

    .sf {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .sf-content {
        display: none;
    }

    .scrolling-images-container {
        display: flex;
        align-items: center;
    }

    .container.container_padding.sf {
        margin-left: 0;
        margin-right: 0;
        max-width: 100% !important;
    }

    .sf-head {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .sf-head {
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px);
    }
}
header {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 58px;
    margin: 20px auto 0;
}

.header_container {
    max-width: 1400px;
}

.header_wrap.header_wrap--scroll {
    background: rgba(13, 13, 13, .8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    display: block;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: fixed;
    top: 0;
    padding: 20px 0;
    z-index: 100;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.header_wrap.header_wrap--scroll .header_container {
    position: relative;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    margin: 0 auto;
}

.header_wrap.header_wrap--scroll ul.menu {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    margin: 0;
}

.header_logo {
    width: 178px;
    height: 50px;
}

.header_logos {
    display: flex;
    align-items: center;
}

.header_logo--geekle {
    width: auto;
    margin-left: 30px;
    height: 17px;
    position: relative;
    top: 22px;
}

.header_logo--geekle img {
    height: 100%;
}

@media screen and (max-width: 1450px) {
    .header_container {
        max-width: 1100px;
    }
}

@media screen and (max-width: 1200px) {
    .header_container {
        max-width: 920px;
    }

    .header_wrap.header_wrap--scroll ul.menu {
        width: 100%;
    }

    nav {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .header_container {
        max-width: 680px;
        padding: 0 10px;
    }
}

@media screen and (max-width: 768px) {
    .header_container {
        max-width: 500px;
    }

    .header_wrap.header_wrap--scroll {
        padding: 5px 0;
    }
}

@media screen and (max-width: 640px) {
    header {
        margin: 10px auto 0;
    }
}

@media screen and (max-width: 576px) {
    .header_container {
        max-width: 100%;
    }
}
.openMenu {
    position: fixed;
    width: 150%;
    background-color: #202123;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s;
    color: #fff;
    padding: 100px 20px 60px;
    border-bottom: 1px solid #D92499;
    overflow: hidden;
    z-index: 2;
}

.openMenu::before {
    content: ' ';
    /* background: #830081; */
    opacity: 0.25;
    -webkit-filter: blur(150px);
            filter: blur(150px);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 50%;
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
}

.openMenu ul {
    margin-left: 0;
}

.openMenu ul li {
    list-style-type: none;
    text-align: center;
    font-size: 20px;
    margin: 0;
}

.openMenu ul li a {
    font-weight: bold;
    font-size: 18px;
    transition: .4s;
    color: #fff;
}

.openMenu_close {
    width: 59px;
    height: 45px;
    margin-left: auto;
    position: absolute;
    right: 39px;
    top: 29px;
}

.openMenu li a {
    height: 38px !important;
}

.openMenu li {
    margin-top: 10px !important;
}

.openMenu_logo {
    margin-right: auto;
    position: absolute;
    left: 40px;
    top: 21.5px;
    max-width: 70px;
}
ul.menu {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
}

ul.menu li {
    text-align: center;
    margin-left: 25px;
}

.menu_pc {
    transition: .4s;
}

ul.menu li a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 18px;
    color: #CBCBCB;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
}

ul.menu li a::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: #D92499;
    border-radius: 20px;
    width: 100%;
    height: 3px;
    opacity: 0;
    transition: .3s ease-in;
}

ul.menu li a:hover {
    color: #fff;
}

/* .header_container .popup_btn--join {
    padding: 0;
    background: transparent;
    height: unset;
    color: #CBCBCB;
    margin-left: 25px;
    font-weight: 400;
}

.header_container .popup_btn--join:hover {
    color: #fff;
}

.header_container .popup_btn--join:hover:before {
    opacity: 1;
}

.header_container .popup_btn--join::after {
    content: none;
}

.header_container .popup_btn--join::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: #D92499;
    border-radius: 20px;
    width: 100%;
    height: 3px;
    opacity: 0;
    transition: .3s ease-in;
} */
.header_container .popup_btn--join {
    width: 161px;
    padding: 0 40px 0 20px;
    margin-left: 25px;
}

.header_container .popup_btn--join::after {
    right: 28px;
}

ul.menu li a:hover {
    box-shadow: none;
}

ul.menu li a:hover::after {
    opacity: 1;
}

.menu_pc:hover a {
    color: #fff;
}

.mobile-menu {
    display: none;
    justify-content: space-between;
    align-items: baseline;
}

.mob_blur_bcg {
    position: absolute;
    top: -30px;
    right: -200px;
    width: 1320px;
    height: 110vh;
    display: block;
    background: #343541;
    -webkit-filter: blur(10px);
            filter: blur(10px);
    opacity: .4;
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

@media screen and (max-width: 1200px) {
    .menu_pc {
        display: none;
    }

    .header_container .popup_btn--join {
        display: none;
    }

    .openMenu .popup_btn--join {
        display: flex;
        margin-left: 0;
        margin-top: 16px;
    }

    .openMenu .popup_btn--join::before {
        content: none;
    }

    ul.menu li {
        margin-left: 0;
    }

    .mobile-menu {
        display: flex;
        /* padding: 10px 20px;  */
        margin-left: auto;
        width: 100%;
    }

    .mobile-menu_img-block {
        width: 38px;
        height: 38px;
        display: flex;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        z-index: 5;
    }

    ul.menu {
        width: 100%;
        left: 50%;
        top: 0px;
        padding-top: 26px;
    }

    .header_wrap--scroll ul.menu {
        padding-top: 0
    }

    ul.menu li a {
        margin: 0 auto;
        padding-bottom: 30px;
        padding-top: 30px;
        color: #fff;
    }

    .header_container .popup_btn--join {
        color: #fff;
    }

    .header_logo {
        display: none;
    }

    .logo-mob {
        max-width: 170px;
        width: 100%;
        z-index: 5;
    }

    .logo-mob__geekle {
        width: auto;
        height: 17px;
        margin-left: 35px;
    }

    .logo-mob__geekle img {
        height: 100%;
    }

    .header_wrap--scroll .header_container {
        padding-top: 0
    }

    .header_container {
        padding-top: 26px;
        margin-top: 0;
    }

    ul.menu li a::before {
        display: none;
    }

    ul.menu li a::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        left: 50%;
        background: #830081;
        width: 25px;
        height: 2px;
    }

    .menu_pc--after a::after {
        display: none;
    }

    ul.menu li.menu_pc--without_before a {
        padding-top: 0;
    }
}

@media screen and (max-width: 768px) {
    .logo-mob {
        max-width: 150px;
    }

    .logo-mob__geekle {
        height: 17px;
        margin-left: 24px;
        top: 8px;
        position: relative;
    }
}
.quote-wrap {
    position: relative;
    overflow-x: clip;
    overflow-y: clip;
}

.quote-wrap::after {
    content: '';
    position: absolute;
    right: -15%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #D92499;
    -webkit-filter: blur(170px);
            filter: blur(170px);
    z-index: -1;
}

.quote-wrap::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #D92499;
    -webkit-filter: blur(170px);
            filter: blur(170px);
    z-index: -1;
}

.quote {
    font-size: 96px;
    text-align: center;
    font-weight: 400;
}

.quote-content {
    display: flex;
    justify-content: center;
}

.quote-img {
    display: none;
}

@media screen and (max-width: 1800px) {

    .quote-wrap::after,
    .quote-wrap::before {
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 1450px) {
    .quote {
        font-size: 75px;
    }

    .quote-wrap::after,
    .quote-wrap::before {
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 1200px) {
    .quote {
        font-size: 63px;
    }
}

@media screen and (max-width: 992px) {
    /* .quote span {
        font-size: 135px;
    } */
    /* 
    .quote span:nth-child(2) {
        font-size: 26px;
        max-width: 160px;
        display: inline-block;
        text-align: left;
        width: 100%;
    } */

    .quote {
        font-size: 46px;
    }

    .quote-wrap::after {
        right: -15%;
        top: unset;
        -webkit-transform: translate(0, 50%) translate3d(0, 0, 0);
                transform: translate(0, 50%) translate3d(0, 0, 0);
        bottom: 0;
        width: 250px;
        height: 250px;
        -webkit-filter: blur(110px);
                filter: blur(110px);
    }

    .quote-wrap::before {
        left: 0%;
        top: 0;
        -webkit-transform: translate(0, -50%) translate3d(0, 0, 0);
                transform: translate(0, -50%) translate3d(0, 0, 0);
        width: 250px;
        height: 250px;
        -webkit-filter: blur(110px);
                filter: blur(110px);
    }
}

@media screen and (max-width: 768px) {
    .quote {
        font-size: 34px;
    }
}

@media screen and (max-width: 576px) {
    .quote {
        display: none;
    }

    .quote-img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .quote-wrap::after,
    .quote-wrap::before {
        width: 200px;
        height: 200px;
        -webkit-filter: blur(100px);
                filter: blur(100px);
    }
}
/* new calendar items */
.calendar{
    margin: 0rem auto 5rem;
}

.calendar-items{
    display: flex;
    flex-direction: column;
}
.calendar-item{
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    border-top: 1px solid #282828;
    padding: 18px 25px 9px;
    font-size: 26px;
}
.calendar-item:last-child{
    border-bottom: 1px solid #282828;
}
.calendar-item_times{
    display: grid;
    grid-template-columns: 150px 150px;
}
.calendar-item_times__date{
    color: #f6f6f6;
}
.calendar-item_times__date span{
    color: #ababab;
}
.calendar-item_times__time{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #ababab;
}
.calendar-item_times__time::before{
    content: '';
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #04690E;
}
.calendar-item_information{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

/* END new calendar items */

.calendar-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.calendar-tabs {
    display: flex;
}

.calendar-tab {
    margin-right: 15px;
    cursor: pointer;
}

/* .calendar-tab:hover {
    color: #D92499;
} */

.calendar-tab.active {
    position: relative;
}

.calendar-tab.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    height: 4px;
    border-radius: 15px;
    background: #D92499;
}

.calendar-tab:last-of-type {
    margin-right: 0;
}

.calendar-title {
    margin-bottom: 10px;
}

.calendar-desc {
    margin-bottom: 30px;
    line-height: 1.15;
}

.calendar-dropdown {
    position: relative;
}

.calendar-header {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.calendar-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #070707;
    border: 1px solid #fff;
    border-radius: 5px;
    z-index: 1;
}

.calendar-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.calendar-content li {
    padding: 10px;
    cursor: pointer;
}

.calendar-content li:hover {
    color: #D92499;
}

.calendar-arrow {
    width: 19px;
    height: 1px;
    position: relative;
    margin-left: 5px;
}

.calendar-arrow::before,
.calendar-arrow::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 1px;
    background-color: #fff;
}

.calendar-arrow::before {
    top: 0;
    left: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.calendar-arrow::after {
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.open .calendar-arrow {
    -webkit-transform: translateY(-50%) rotate(-180deg);
            transform: translateY(-50%) rotate(-180deg);
}

/*Month*/

.calendar-month_title {
    line-height: 1;
    text-align: center;
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.calendar-month:first-of-type .calendar-month_title {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

/*Day*/

.calendar-day {
    display: flex;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.calendar-day_head {
    width: 150px;
    padding-right: 15px;
}

.calendar-day_pic {
    width: 124px;
    height: auto;
    object-fit: contain;
    margin-right: 30px;
    border-radius: 5px;
}

.calendar-day_content-desc {
    line-height: 1.1;
}

.calendar-btn {
    margin-top: 50px;
}

.calendar-day:hover {
    background: rgba(217, 36, 153, 0.1);
}

.calendar-img--mob {
    display: none;
}

.calendar-img {
    width: 100%;
    object-fit: contain;
    height: auto;
}

@media screen and (max-width: 992px) {
    .calendar-day_head {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    /* new calendar items */
    .calendar-item{
        padding: 20px 0px 18px;
        grid-gap: 0px;
        line-height: 1.3em;
    }
    .calendar-item_times{
        grid-template-columns: 150px;
    }
    .calendar-item_times__time::before{
        display: none;
    }
    .calendar-item_information{
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    /* END new calendar items */

    .calendar-day_pic {
        width: 53px;
        margin-right: 10px;
    }

    .calendar-day {
        align-items: flex-start;
    }

    .calendar-day_content {
        display: flex;
        flex-direction: column;
    }

    .calendar-day_content-title {
        order: 2;
    }

    .calendar-day_content-desc {
        order: 1;
        margin-bottom: 6px;
    }

    .calendar-img--mob {
        display: block;
    }

    .calendar-img--pc {
        display: none;
    }
}
@media screen and (max-width: 450px) {
    /* new calendar items */
    .calendar-item{
        font-size: 18px;
        line-height: 1.2em;
    }
    .calendar-item_times{
        grid-template-columns: 120px;
    }
    .calendar-item_information{
        grid-template-columns: 50px 1fr;
        grid-gap: 10px;
    }

    /* END new calendar items */
}
.incubator {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.incubator-wrap {
    position: relative;
    background: #fee6ff;
}

.incubator-card_content {
    padding: 15px;
}

.incubator-title {
    color: #2d712a;
    margin-bottom: 10px;
}

.incubator-desc {
    margin-bottom: 30px;
    line-height: 1.1;
    color: #2d712a;
}

.incubator-content {
    display: flex;
    flex-wrap: wrap;
}

.incubator-card {
    width: calc((100% / 5) - 16px);
    margin-right: 20px;
    margin-bottom: 20px;
    background: #141414;
    border-radius: 5px;
    overflow: hidden;
}

.incubator-card:last-of-type {
    margin-right: 0;
}

.incubator-card_btn:hover span {
    color: #fff;
}

.incubator-card_btn:hover path {
    fill: #fff;
}

.incubator-card_img {
    width: 100%;
    object-fit: cover;
}

.incubator-card_btn {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.incubator-card_btn span {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: underline;
    color: #D92499;
}

.incubator-card_btn svg {
    width: 13px !important;
    margin-left: 8px;
}

.incubator-card .popup_btn--join {
    margin-top: 18px;
    line-height: 1;
    padding: 0;
    background: transparent;
    height: unset;
    color: #2d712a;
    text-decoration: underline;
}

.incubator-card .popup_btn--join:hover {
    color: #fff;
}

.incubator-card {
    transition: .3s;
}

.incubator-card:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

.incubator-card .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_yellow.312e6a81.svg);
    right: -17px;
}

.incubator-card .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr.6b604341.svg);
}

.incubator-motivation_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.incubator-motivation_img {
    width: 191px;
    margin-right: 100px;
    -webkit-transform: rotate(17deg) translateY(20px);
            transform: rotate(17deg) translateY(20px);
}

.incubator-motivation_title {
    position: relative;
    font-weight: 700;
    font-size: 36px;
    color: #2d712a;
    padding-left: 10px;
    margin-bottom: 10px;
}

.incubator-motivation_title span {
    color: #2d712a;
}

.incubator-motivation_title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #2d712a;
    border-radius: 15px;
}

.incubator-motivation_desc {
    line-height: 1.1;
    color: #2d712a;
}

.incubator-btn_wrap .popup_btn--join {
    background: #2d712a;
    margin: 20px 20px 20px 0;
    color: #fff;
}

.incubator-btn_wrap .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.incubator-btn_wrap .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.incubator-btn_wrap .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_white.79137ebf.svg);
}

.incubator-card_title{
    margin: 5px 0 10px;
}

.incubator-link {
    border: 1px solid #2d712a;
    background: transparent;
    padding: 0 59px;
    margin-top: 20px;
    color: #2d712a;
}
.incubator-link::after {
    display: none;
}

.incubator-link:hover {
    background: #2d712a;
    color: #fff;
}

.incubator-link{
    border: 1px solid #2d712a;
    background: transparent;
    margin-top: 20px;
    color: #2d712a;
}

.incubator-link:hover{
    background: #2d712a;
    color: #fff;
}

@media screen and (max-width: 1450px) {
    .incubator-motivation_img {
        margin-right: 60px;
    }

    .incubator-wrap::after {
        width: 370px;
        height: 370px;
        -webkit-filter: blur(300px);
                filter: blur(300px);
    }
}

@media screen and (max-width: 1200px) {
    .incubator-card {
        width: calc((100% / 3) - 20px);
    }

    .incubator-card:nth-child(3n) {
        margin-right: 0;
    }

    .incubator-wrap::after {
        width: 270px;
        height: 270px;
        -webkit-filter: blur(200px);
                filter: blur(200px);
    }
}

@media screen and (max-width: 992px) {
    .incubator-motivation_img {
        position: absolute;
        right: 50px;
        top: 70px;
        width: 141px;
        height: 141px;
        margin-right: 0;
    }

    .container.container_padding.incubator {
        padding-top: 80px;
    }

    .incubator-motivation_block {
        margin-bottom: 70px;
    }

    .incubator-card {
        width: calc((100% / 2) - 10px);
    }

    .incubator-card:nth-child(3n) {
        margin-right: 20px;
    }

    .incubator-card:nth-child(2n) {
        margin-right: 0px;
    }

    .incubator-desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 768px) {
    .incubator-motivation_img {
        top: 40px;
    }

    .incubator-motivation_desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 576px) {
    .incubator-card {
        width: calc((100% / 2) - 10px);
        /* margin-right: 0; */
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
    }

    .incubator-motivation_title {
        font-size: 26px;
    }

    .incubator-motivation_img {
        right: unset;
        left: 170px;
        top: 67px;
        width: 100px;
        height: 100px;
    }

    .incubator-wrap::after {
        width: 130px;
        height: 130px;
        -webkit-filter: blur(90px);
                filter: blur(90px);
        top: 0%;
        right: -28%;
    }
}
@media screen and (max-width: 480px) {
    .incubator-btn_wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .incubator-btn_wrap .popup_btn--join{
        margin: 0;
    }
}
.practices {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.container.container_padding.practices {
    padding-top: 15px;
}

.practices-wrap {
    position: relative;
}

.practices-wrap::after {
    content: '';
    position: absolute;
    right: -15%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
    width: 470px;
    height: 470px;
    background: #FFB800;
    -webkit-filter: blur(350px);
            filter: blur(350px);
    z-index: -1;
}

.practices-card_content {
    padding: 15px;
}

.practices-title {
    margin-bottom: 10px;
}

.practices-desc {
    margin-bottom: 30px;
    line-height: 1.1;
}

.practices-content {
    display: flex;
    flex-wrap: wrap;
}

.practices-card {
    width: calc((100% / 4) - 15px);
    margin-right: 20px;
    margin-bottom: 20px;
    background: #141414;
    border-radius: 5px;
    overflow: hidden;
}

.practices-card:last-of-type {
    margin-right: 0;
}

.practices-card_btn:hover span {
    color: #fff;
}

.practices-card_btn:hover path {
    fill: #fff;
}

.practices-card_img {
    width: 100%;
    object-fit: cover;
}

.practices-card_btn {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.practices-card_btn span {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: underline;
    color: #D92499;
}

.practices-card_btn svg {
    width: 13px !important;
    margin-left: 8px;
}

.practices-card .popup_btn--join {
    margin-top: 18px;
    line-height: 1;
    padding: 0;
    background: transparent;
    height: unset;
    color: #FFB800;
    text-decoration: underline;
}

.practices-card .popup_btn--join:hover {
    color: #fff;
}

.practices-card {
    transition: .3s;
}

.practices-card:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

.practices-card .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_yellow.312e6a81.svg);
    right: -17px;
}

.practices-card .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr.6b604341.svg);
}

.practices-motivation_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.practices-motivation_img {
    width: 191px;
    margin-right: 100px;
    -webkit-transform: rotate(17deg) translateY(20px);
            transform: rotate(17deg) translateY(20px);
}

.practices-motivation_title {
    font-weight: 700;
    font-size: 36px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 10px;
}

.practices-motivation_title span {
    color: #FFB800;
}

.practices-motivation_title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #FFB800;
    border-radius: 15px;
}

.practices-motivation_desc {
    line-height: 1.1;
}

.practices-btn_wrap .popup_btn--join {
    background: #FFB800;
    margin-top: 20px;
    color: #070707;
}

.practices-btn_wrap .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.practices-btn_wrap .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.practices-btn_wrap .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

@media screen and (max-width: 1450px) {
    .practices-motivation_img {
        margin-right: 60px;
    }

    .practices-wrap::after {
        width: 370px;
        height: 370px;
        -webkit-filter: blur(300px);
                filter: blur(300px);
    }
}

@media screen and (max-width: 1200px) {
    .practices-card {
        width: calc((100% / 3) - 20px);
    }

    .practices-card:nth-child(3n) {
        margin-right: 0;
    }

    .practices-wrap::after {
        width: 270px;
        height: 270px;
        -webkit-filter: blur(200px);
                filter: blur(200px);
    }
}

@media screen and (max-width: 992px) {
    .practices-motivation_img {
        position: absolute;
        right: 50px;
        top: 70px;
        width: 141px;
        height: 141px;
        margin-right: 0;
    }

    .container.container_padding.practices {
        padding-top: 80px;
    }

    .practices-motivation_block {
        margin-bottom: 70px;
    }

    .practices-card {
        width: calc((100% / 2) - 10px);
    }

    .practices-card:nth-child(3n) {
        margin-right: 20px;
    }

    .practices-card:nth-child(2n) {
        margin-right: 0px;
    }

    .practices-desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 768px) {
    .practices-motivation_img {
        top: 40px;
    }

    .practices-motivation_desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 576px) {
    .practices-card {
        width: 100%;
        margin-right: 0;
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
    }

    .practices-card:nth-child(3n) {
        margin-right: 0px;
    }

    .practices-motivation_title {
        font-size: 26px;
    }

    .practices-motivation_img {
        right: unset;
        left: 170px;
        top: 67px;
        width: 100px;
        height: 100px;
    }

    .practices-wrap::after {
        width: 130px;
        height: 130px;
        -webkit-filter: blur(90px);
                filter: blur(90px);
        top: 0%;
        right: -28%;
    }
}
.prompts {
    display: flex;
    justify-content: space-between;
}

.prompts-wrap {
    background: #141414;
}

.prompts-head {
    margin-right: 64px;
}

.prompts-search-block-wrap {
    max-width: 675px;
}

.prompts-search-block {
    display: flex;
    align-items: center;
    /* border: 1px solid #3F3F3F; */
    border-radius: 5px;
    margin-bottom: 30px;
}

.prompts-search-btn {
    background: #FFB800;
    padding: 1.53px 0px;
    border-radius: 0 5px 5px 0;
}

.prompts-search-block svg {
    width: 21px;
    margin: 12px;
}

.prompts-search-block input {
    background: #fff;
    border: none;
    box-shadow: none;
    color: #070707;
    background-color: #fff;
    border-radius: 5px 0 0 5px;
}

.prompts-search-block input::-webkit-input-placeholder {
    color: #CBCBCB;
}

.prompts-search-block input:-ms-input-placeholder {
    color: #CBCBCB;
}

.prompts-search-block input::placeholder {
    color: #CBCBCB;
}

.prompts-card_head {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.prompts-card_head svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

/* .prompts-card_head svg:hover path {
    stroke: #000;
}

.prompts-card_head svg:hover rect {
    fill: #fff;
} */

.prompts-card_head svg:last-of-type {
    margin-right: 0;
}

.practices-card_tag {
    background: rgba(255, 184, 0, 0.25);
    border-radius: 5px;
    padding: 8px;
    margin-right: 32px;
}

.prompts-title {
    margin-bottom: 5px;
}

.prompts-card {
    margin-bottom: 30px;
}

.prompts-card:last-of-type {
    margin-bottom: 0;
}

.prompts-wrap .popup_btn--join {
    background: #FFB800;
    margin-top: 40px;
    color: #070707;
}

.prompts-wrap .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.prompts-wrap .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.prompts-wrap .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.prompts-search-block-wrap .popup_btn--join {
    display: none;
}

@media screen and (max-width: 1200px) {
    .prompts {
        flex-direction: column;
    }

    .prompts-head {
        margin-bottom: 30px;
    }

    .prompts-search-block-wrap {
        max-width: unset;
    }

    .prompts-head .popup_btn--join {
        display: none;
    }

    .prompts-search-block-wrap .popup_btn--join {
        display: flex;
    }
}
.enjoy {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.enjoy-content {
    max-width: 600px;
}

.enjoy-title {
    margin-bottom: 10px;
}

.enjoy-desc {
    margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
    .enjoy {
        flex-direction: column;
    }

    .enjoy-head .popup_btn--join {
        display: none;
    }

    .enjoy-content {
        max-width: unset;
    }

    .enjoy-gif {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .enjoy-gif {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}
.footer_wrap {
    position: relative;
    padding: 1px 0;
    flex: 0 0 auto;
    overflow-x: clip;
}

.footer_wrap::before {
    content: '';
    position: absolute;
    right: -15%;
    top: 20%;
    width: 470px;
    height: 470px;
    background: #D92499;
    -webkit-filter: blur(350px);
            filter: blur(350px);
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0) translate(-50%, -50%);
            transform: translate3d(0, 0, 0) translate(-50%, -50%);
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;
}

.footer_geekle_logo {
    max-width: 232px;
    height: auto;
    width: 100%;
    margin: 0 auto;
}

.footer_links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_link_block {
    display: flex;
    margin-top: auto;
}

.footer_link_img {
    width: 26px;
    height: 26px;
    object-fit: contain;
}

.footer_link {
    text-decoration: none;
    margin-right: 46px;
}

.footer,
.footer_contact {
    color: #fff;
}

.footer_link_geekle {
    text-decoration: none;
    max-width: 252px;
    width: 100%;
}

.footer_link:last-child {
    margin-right: 0;
}

.footer_address {
    margin-top: auto;
}

.footer_contacts {
    display: flex;
    flex-direction: column;
}

.footer_docs {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.footer_contacts_wrap {
    align-items: center;
    display: flex;
}

.footer_contacts_wrap {
    margin-bottom: 12px;
}

.footer_contacts_wrap:last-child {
    margin-bottom: 0;
}

.footer_contacts_wrap svg {
    width: 18px;
    margin-right: 19px;
}

.footer_docs_item {
    font-weight: 400;
    display: flex;
    align-items: center;
}

.footer_address {
    text-align: center;
    margin-bottom: 20px;
}

.footer_address--mob {
    display: none;
}

.footer_docs_item span {
    color: #D92499;
    text-decoration: underline;
}

.footer_docs_item svg {
    width: 13px;
    margin-left: 5px;
}

.footer_docs_item:first-of-type {
    margin-bottom: 20px;
}

.chat_block {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.chat_btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
}

.chat_title {
    font-size: 38px;
}

.chat_undertitle {
    margin-bottom: 20px;
}

.chat_btn svg {
    width: 21px;
    margin-right: 12px;
}

.chat_btn path {
    transition: .4s;
}

.chat_btn:hover path {
    fill: #0B0B0B;
}

.footer_docs_item:hover span {
    color: #fff;
}

.footer_docs_item:hover path {
    fill: #fff;
}

.footer_link_geekle.footer_link_geekle--small {
    max-height: 70px;
    width: auto;
    margin-bottom: 28px;
}

.footer_link_geekle {
    margin-bottom: 13px;
}

.footer_link_geekle--small img {
    height: 100%;
    width: 88px;
}

@media screen and (max-width: 1600px) {
    .footer_wrap::before {
        width: 370px;
        height: 370px;
        -webkit-filter: blur(300px);
                filter: blur(300px);
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .footer_item {
        width: 100%;
        align-items: center;
    }

    .footer_address {
        margin-bottom: 0px;
    }

    .footer_contacts {
        margin-bottom: 50px;
    }

    .chat_block {
        order: 1;
        padding-bottom: 80px;
        margin-bottom: 50px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        width: 100%;
    }

    .footer_links {
        order: 2;
        margin-bottom: 40px;
    }

    .footer_link {
        margin-right: 46px;
    }

    .footer_info {
        order: 3;
        text-align: center;
    }

    .footer_docs_item:first-of-type::after {
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }

    .footer_contacts_wrap {
        justify-content: center;
    }

    .footer_address {
        padding: 0;
    }

    .footer_address--pc {
        display: none;
    }

    .footer_address--mob {
        display: block;
    }

    .chat_title {
        font-size: 24px;
    }
}

@media screen and (max-width: 576px) {
    .chat_block {
        text-align: left;
    }

    .chat_btn {
        margin-left: 0;
    }

    .footer_wrap::before {
        width: 165px;
        height: 165px;
        -webkit-filter: blur(150px);
                filter: blur(150px);
    }

    .footer_item {
        align-items: flex-start;
        width: 100%;
    }

    .footer_info {
        width: 100%;
    }

    .footer_address {
        text-align: left;
    }

    .footer_link_geekle {
        margin-bottom: 24px;
    }

    .footer_link_geekle.footer_link_geekle--small {
        max-height: 88px;
        height: 88px;
    }

    .footer_geekle_logo {
        object-fit: contain;
    }
}
.packages-row {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 2px solid #282828;
    align-items: center;
}

.packages-item_top--btn_block {
    display: flex;
    width: calc(100% - 45px);
}

.packages-item_top--btn_block.two {
    width: 100%;
}

.packages {
    margin-top: 80px;
}

.packages-top.packages-row {
    align-items: stretch;
}

.packages-row_part {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 66%;
}

.packages-price_block--full .packages-price_title-block {
    display: flex;
    align-items: center;
}

.packages-price_block.packages-price_block--border {
    border-bottom: none;
}

.packages-top_title-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 300px;
}

.packages-top_reliable-list li {
    display: flex;
}

.packages-top_reliable-list svg {
    width: 16px;
    object-fit: contain;
    margin-right: 7px;
    position: relative;
    top: 6px;
}

.packages-top_title-block--inner {
    margin-bottom: 30px;
}

.packages-top_discount {
    max-width: 160px;
    height: auto;
    object-fit: contain;
}

.packages-top.packages-row {
    border-bottom: unset;
}

.packages-top_row-part {
    border-bottom: unset;
}

.packages-item {
    width: calc(100% / 5);
    padding: 16px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.packages-row:last-of-type {
    border-bottom: unset;
}

.packages-item_top {
    padding: 20px 0 0 0;
    flex-direction: column;
}

.packages-item_top-btn.button.mr {
    margin-right: 15px;
}

.packages-item_top.active {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.packages-item.active {
    background-color: #14161A;
}

.packages-top_title {
    font-size: 36px;
    text-align: left;
    /* margin-bottom: 12px; */
    font-weight: 700;
    padding-left: 10px;
}

.packages-top_title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: #D92499;
    border-radius: 10px;
    width: 3px;
    height: 36px;
}

.packages-top_title span {
    color: #D92499;
}

.packages-top_title-block-hr {
    height: 4px;
    width: 20px;
    background: #D92499;
    margin-top: 4px;
}

.packages-best_value {
    padding: 4px 12px;
    border-radius: 20px;
    background: #fff;
    color: #070707;
    top: -10px;
    position: relative;
}

.packages-item_top-title {
    font-weight: 600;
    color: #fff !important;
    text-transform: uppercase;
}

.packages-item_top-desc {
    margin-bottom: 24px;
}

.packages-item_text {
    color: #ADADAD;
    line-height: 1.2;
    display: flex;
    align-items: center;
}

.packages-item_text.active {
    color: #fff;
}

.packages-item_top-btn {
    min-width: unset;
    width: 100%;
    padding: 0 12px 0 12px;
    line-height: 1.1;
}

.packages-item_top-btn::after {
    right: 10px;
    content: none;
}

.packages-item_top.packages-item.active .packages-item_top-btn {
    border-color: #D92499;
    background: #D92499;
}

.packages-item_top.packages-item.active .packages-item_top-btn::after {
    right: 18px;
    background-image: url(/static/media/btn-arr.6b604341.svg);
}

.packages-item_price {
    color: #fff;
    /* padding: 16px 0; */
    font-weight: 400;
    font-size: 28px;
    position: relative;
}

.packages-row:nth-child(4) {
    border-top: 2px solid #282828;
}

.packages-price_block--full .packages-item {
    padding: 0 5px;
}

.packages-item_price.active {
    color: #fff;
}

.packages-item_price--full::after {
    content: '';
    position: absolute;
    top: 28px;
    left: 0;
    width: 100%;
    height: 3px;
    background: #949aa4;
    -webkit-transform: rotate(168deg);
            transform: rotate(168deg);
}

.packages-item_price.active::after {
    background: #fff;
}

.packages-btn-main {
    margin: 30px auto 0;
    display: flex;
    width: 362px;
    height: 44px;
}

.packages-wrap--mob {
    display: none;
}

.packages-item-title {
    text-transform: uppercase;
    font-weight: 400;
}

.packages-top_desc {
    text-align: left;
}

.packages-item_top.packages-item.colorful {
    background: rgba(217, 36, 153, 0.2);
    border-radius: 10px 10px 0 0;
}

.packages-item.colorful {
    background: rgba(217, 36, 153, 0.2);
}

.packages-row:last-of-type .packages-item.colorful {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.packages-item_full-price {
    color: #FFB800;
    position: relative;
}

.packages-item_full-price::after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0;
    height: 1px;
    width: 100%;
    background: #FFB800;
}

.packages-price_prices .packages-item_prices {
    display: flex;
    flex-direction: column;
}

.packages-price_prices .packages-item-row{
    flex-direction: row;
    justify-content: space-evenly;
    padding: 16px 0px;
}
.packages-price_prices .packages-item-row span{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.packages-price_prices .packages-item_price {
    margin-bottom: 9px;
}

.packages-item_title {
    text-align: left;
    line-height: 1.1;
}

.packages-special_title-block {
    width: calc(100% / 5);
}

.packages-item .popup_btn--join {
    min-width: unset;
    width: 100%;
    padding: 0 12px 0 12px;
    line-height: 1.1;
}

.packages-item .popup_btn--join::after {
    content: unset;
}

@media screen and (max-width: 1450px) {
    .packages-row_part {
        width: 80%;
    }

    .packages-top_discount {
        max-width: 120px;
        margin-left: 10px;
    }

    .packages-item_price {
        font-size: 24px;
    }

    .packages-top_title-block {
        max-width: 190px;
        text-align: left;
    }

    .packages-top_reliable-list span {
        font-size: 16px;
    }

    .packages-top_reliable-list svg {
        top: 5px;
    }
}

@media screen and (max-width: 1200px) {
    .packages-wrap--pc {
        display: none;
    }

    .packages-top_title-block {
        max-width: unset;
    }

    .packages-wrap--mob {
        display: block;
    }

    .packages-item-title {
        font-size: 27px;
        line-height: 1.1;
    }

    .packages-item-row.packages-item-row_price-block {
        border-bottom: unset;
        padding: 0;
    }

    .packages-top_title-block {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .packages-top.packages-row {
        margin-bottom: 30px;
    }

    .packages-top_discount {
        margin: 25px 0;
        display: none;
    }

    .packages-top_title {
        font-size: 37px;
    }


    .packages-items {
        display: flex;
        flex-wrap: wrap;
    }

    .packages-item {
        width: calc((100% / 2) - 15px);
        flex-direction: column;
        background: #14161A;
        border-radius: 10px;
        padding: 20px 20px 4px;
        margin-bottom: 60px;
    }

    .packages-item:last-of-type {
        margin-bottom: 0;
    }

    .packages-item_top-btn {
        padding: 0 24px 0 12px;
    }

    .packages-item_top-btn::after {
        content: none;
    }

    .packages-item:nth-child(odd) {
        margin-right: 30px;
    }

    .packages-item_top-btn {
        padding: 0 24px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .packages-item-desc {
        margin-bottom: 20px;
    }

    .packages-item_top--btn_block {
        width: 100%;
        margin-bottom: 21px;
    }

    .packages-item_top-btn {
        width: 100%;
    }

    .packages-item_top-btn.button.half-wide {
        width: 50%;
    }

    .packages-item-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #282828;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .packages-item-row:last-of-type {
        border-bottom: unset;
    }

    .packages-item-row_priceFull {
        position: relative;
    }

    .packages-item-row_priceFull::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background: #949aa4;
        -webkit-transform: rotate(168deg) translateY(-50%);
                transform: rotate(168deg) translateY(-50%);
    }

    .packages-item-row_price {
        color: #fff;
        font-weight: 700;
    }

    .packages-item-row_price--white {
        color: #fff;
    }

    .packages-item-row_title {
        width: 50%;
        padding-right: 11px;
        text-align: right;
        line-height: 1.1;
    }

    .packages-item-row_price {
        width: 50%;
        text-align: left;
        padding-left: 11px;
    }

    .packages-item-row_price-block {
        display: flex;
        justify-content: center;
    }

    .packages-item-row_price-block .packages-item-row_price {
        padding-left: 0;
        width: 100%;
        text-align: center;
    }

    .packages-item-row_full-price-block .packages-item-row_price {
        padding-left: 0;
        width: 100%;
        text-align: center;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(1) {
        text-align: right;
        padding-right: 11px;
        margin-right: 0;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(2) {
        text-align: left;
        padding-left: 11px;
        margin-left: 0;
    }

    .packages-item-row_full-price-block {
        padding-top: 0;
    }

    .packages-item-row.packages-item-row_full-price-block .packages-item-row_price {
        font-weight: 400;
        font-size: 14px;
        color: #FFB800;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        position: relative;
    }

    .packages-item-row.packages-item-row_full-price-block .packages-item-row_price::after {
        content: '';
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        left: 0;
        height: 1px;
        width: 100%;
        background: #FFB800;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(1),
    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(2) {
        position: relative;
        width: inherit;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(1)::after,
    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(2)::after {
        content: '';
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        left: 11px;
        height: 1px;
        width: 30px;
        background: #FFB800;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(1)::after {
        right: 11px;
        left: unset;
    }

    .packages-item-row.packages-item-row_price-block.two .packages-item-row_price:nth-child(1) {
        text-align: right;
        padding-right: 11px;
    }

    .packages-item-row.packages-item-row_price-block.two .packages-item-row_price:nth-child(2) {
        text-align: left;
        padding-left: 11px;
    }

    .packages-item-row_price-block .packages-item-row_price {
        font-weight: 900;
    }
}

@media screen and (max-width: 992px) {
    .packages-item {
        width: 100%;
    }

    .packages-item:nth-child(odd) {
        margin-right: 0;
    }
}

@media screen and (max-width: 768px) {
    .packages-top_title-block--inner {
        max-width: 300px;
    }

    .packages-top_title::before {
        height: 72px;
    }

    .packages-item-row_title,
    .packages-item-row_price {
        font-size: 14px;
    }
}
.packages-head {
    text-align: center;
    padding: 150px 0 80px;
    margin-top: 0;
    margin-bottom: 0;
}

.packages-head_wrap {
    position: relative;
    overflow-y: clip;
}

.packages-head_wrap::after {
    content: '';
    position: absolute;
    left: 5%;
    top: 0%;
    width: 470px;
    height: 470px;
    background: #D92499;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0) translate(-60%, -80%);
            transform: translate3d(0, 0, 0) translate(-60%, -80%);
}

.packages-head_wrap::before {
    content: '';
    position: absolute;
    right: -15%;
    bottom: -20%;
    width: 470px;
    height: 470px;
    background: #D92499;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0) translate(-50%, 60%);
            transform: translate3d(0, 0, 0) translate(-50%, 60%);
}

.packages-head_title {
    font-size: 65px;
    margin-bottom: 25px;
    line-height: 1.1;
}

.packages-head_title span {
    color: #D92499;
}

.packages-head_desc {
    font-weight: 700;
    font-size: 46px;
    text-transform: uppercase;
}

.packages-head_img {
    display: none;
}

.packages-head_design {
    display: none;
}

@media screen and (max-width: 1600px) {
    .packages-head_wrap::before {
        width: 370px;
        height: 370px;
        -webkit-filter: blur(200px);
                filter: blur(200px);
        right: -17%;
    }

    .packages-head_wrap::after {
        width: 370px;
        height: 370px;
        -webkit-filter: blur(200px);
                filter: blur(200px);
        -webkit-transform: translate3d(0, 0, 0) translate(-50%, -70%);
                transform: translate3d(0, 0, 0) translate(-50%, -70%);
    }
}

@media screen and (max-width: 992px) {
    .packages-head_wrap::before {
        width: 270px;
        height: 270px;
        -webkit-filter: blur(150px);
                filter: blur(150px);
        right: -27%;
    }

    .packages-head_wrap::after {
        width: 270px;
        height: 270px;
        -webkit-filter: blur(150px);
                filter: blur(150px);
    }
}

@media screen and (max-width: 768px) {
    .packages-head_title {
        display: none;
    }

    .packages-head_img {
        display: block;
        width: 100%;
        height: auto;
    }

    .packages-head {
        padding: 180px 10px 80px;
    }

    .packages-head_desc {
        font-size: 26px;
    }

    .packages-head_design {
        display: block;
        position: absolute;
        object-fit: contain;
        top: 0;
        right: -200px;
        width: 300px;
        height: 300px;
        -webkit-transform: scale(2);
                transform: scale(2);
        z-index: -1;
    }
}
.mails-wrap {
    overflow: hidden;
    position: relative;
}

.mails-design {
    position: absolute;
    top: 50%;
    right: -100px;
    -webkit-transform: translateY(-32%);
            transform: translateY(-32%);
    z-index: -1;
}

.mails-wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 50%;
    width: 500px;
    height: 500px;
    background: #1A9317;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0) translate(0, 90%);
            transform: translate3d(0, 0, 0) translate(0, 90%);
}

.mails-wrap::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    -webkit-transform: translate(0, -50%) translate3d(0, 0, 0);
            transform: translate(0, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #1A9317;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
}

.mails-title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 36px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 10px;
}

.mails-title span {
    color: #1A9317;
}

.mails-title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #1A9317;
    border-radius: 15px;
}

.mails-desc {
    margin-bottom: 30px;
}

.mails-form_label {
    margin-bottom: 5px;
}

.mails-join-beta {
    position: absolute;
    top: 50%;
    right: 17%;
    -webkit-transform: translateY(-50%) rotate(15deg);
            transform: translateY(-50%) rotate(15deg);
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.mails-checkbox_label {
    line-height: 1.1;
}

@media screen and (max-width: 1600px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 360px;
        height: 360px;
        -webkit-filter: blur(250px);
                filter: blur(250px);
    }

    .mails-join-beta {
        width: 250px;
        height: 250px;
        right: 370px;
    }
}

@media screen and (max-width: 1450px) {
    .mails-join-beta {
        right: 100px;
    }
}

@media screen and (max-width: 1200px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 290px;
        height: 290px;
        -webkit-filter: blur(170px);
                filter: blur(170px);
    }

    .mails-wrap::before {
        top: 29%;
    }

    .mails-wrap::after {
        bottom: 40%;
    }
}

@media screen and (max-width: 992px) {
    .mails-join-beta {
        width: 150px;
        height: 150px;
        right: unset;
        top: unset;
        bottom: -50px;
        left: 350px;
    }

    .mails-wrap::before,
    .mails-wrap::after {
        width: 260px;
        height: 260px;
        -webkit-filter: blur(160px);
                filter: blur(160px);
    }

    .mails-wrap::before {
        -webkit-transform: translate(0, -80%) translate3d(0, 0, 0);
                transform: translate(0, -80%) translate3d(0, 0, 0);
    }

    .mails-wrap::after {
        -webkit-transform: translate(0, 120%) translate3d(0, 0, 0);
                transform: translate(0, 120%) translate3d(0, 0, 0);
    }

    .mails-design {
        display: none;
    }
}

@media screen and (max-width: 768px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 210px;
        height: 260px;
        -webkit-filter: blur(140px);
                filter: blur(140px);
    }

    .mails-desc {
        max-width: 330px;
    }
}

@media screen and (max-width: 576px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 180px;
        height: 180px;
        -webkit-filter: blur(110px);
                filter: blur(110px);
    }

    .mails-join-beta {
        width: 100px;
        height: 100px;
        right: unset;
        top: unset;
        bottom: 0px;
        left: 250px;
    }
}
.form_popup {
    position: fixed;
    inset: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    font-size: 30px;
    text-align: center;
}

.form_popup-modal {
    width: 800px;
    padding: 50px 20px;
    border-radius: 5px;
    background: #070707;
    border: 1px solid #303136;
}

.form_popup-close {
    position: absolute;
    right: 20px;
    top: 17px;
    width: 25px;
    height: 25px;
}

.mails-input-btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.mails-input-btn:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.mails-input-btn::after {
    content: '';
    position: absolute;
    right: 54px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url(/static/media/btn-arr.6b604341.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.mails-btn {
    box-shadow: none;
    background-color: #1A9317;
}

.mails-form_input-block {
    margin-bottom: 20px;
}

.form-error {
    color: #FF0000;
    font-size: 13px;
}

.mails_form--input {
    max-width: 467px;
    background: #0E0E0E;
    border-radius: 5px;
}

.mails-checkbox_block {
    display: flex;
    margin-bottom: 40px;
    max-width: 420px;
}

.mails-checkbox_block-mbn {
    margin-bottom: 10px;
}

.mails-checkbox {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-right: 12px;
    padding: 0;
    background: #0E0E0E;
    border: unset;
    position: relative;
    cursor: pointer;
}

.mails-checkbox_label a {
    color: #1A9317;
}

.mails-checkbox:after,
.mails-checkbox:before {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 4px;
    width: 0;
    height: 2px;
    background: #D92499;
    border-radius: 3px;
    -webkit-transform-origin: center left;
            transform-origin: center left;
    transition: .2s ease;
}

.mails-wrap .mails-checkbox:after,
.mails-wrap .mails-checkbox:before {
    background: #1A9317;
}

.mails-checkbox:before {
    -webkit-transform: rotate(57deg);
            transform: rotate(57deg);
    left: 7px;
    bottom: 13px;
    transition-delay: .18s;
}

.mails-checkbox:after {
    -webkit-transform: rotate(-58deg);
            transform: rotate(-58deg);
    transition-delay: 0ms;
}

.mails-checkbox:checked {
    background: #fff;
    box-shadow: inset 0 0 0 1px #fff;
    color: #fff;
}

.mails-checkbox:checked:before {
    width: 11px;
    transition-delay: 0ms;
}

.mails-checkbox:checked:after {
    width: 15px;
    transition-delay: .18s;
}

@media screen and (max-width: 992px) {
    .form_popup-modal {
        width: 500px;
    }

    .try-form {
        grid-template-columns: [col] auto;
        grid-template-rows: [row] auto [row] auto [row] auto;
    }

    .try-btn {
        grid-column: col;
        grid-row: row 3;
        margin-top: 12px;
    }

    .try-form_input-block {
        grid-column: col;
        grid-row: row 1;
        margin-right: 0;
    }

    .try-checkbox_block {
        grid-column: col;
        grid-row: row 2;
    }
}

@media screen and (max-width: 768px) {
    .form_popup-modal {
        width: 320px;
        padding: 44px 20px 34px;
    }

    .form_popup-close {
        right: 15px;
        top: 15px;
        width: 20px;
        height: 20px;
    }
}
.faq-accordion {
    display: flex;
    flex-direction: column;
}

.faq {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.faq-accordion {
    max-width: 680px;
    width: 100%;
}

.faq-accordion:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 992px) {
    .faq {
        flex-direction: column;
    }

    .faq-title {
        margin-bottom: 30px;
    }

    .faq-accordion {
        max-width: unset;
    }
}

@media screen and (max-width: 768px) {}
.accordion-item {
    padding: 16px;
    margin-bottom: 16px;
    background: #1B1B1B;
    border-radius: 4px;
    color: #fff;
    text-align: left;
}

.accordion-item:last-child {
    margin-bottom: 0;
}

.accordion-desc {
    margin-top: 16px;
    white-space: pre-line;
    opacity: .8;
    line-height: 1.3;
}

.accordion-title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.accordion-item_head svg {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    min-width: 20px;
}

.accordion-item_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.categories {
    display: flex;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
}

.categories-head {
    margin-right: 50px;
    max-width: 323px;
    width: 100%;
}

.categories-title {
    margin-bottom: 30px;
}

.categories-content {
    display: flex;
    align-items: flex-start;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-wrap: wrap;
}

.categories-checkbox_label {
    text-transform: capitalize;
    cursor: default;
    font-weight: 300;
}

.categories-item {
    background: #272727;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 7px;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: .25s ease;
    cursor: default;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.main-form_block,
.main-categories {
    width: 45%;
    margin-left: 2%;
}

.main-form_block--inner {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.main-form {
    margin-right: 8px;
}

.categories-checkbox_block {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.categories-checkbox {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-right: 12px;
    padding: 0;
    background: #272727;
    border: unset;
    box-shadow: inset 0 0 0 1px #949aa4;
    position: relative;
    cursor: pointer;
}

.categories-checkbox:after,
.categories-checkbox:before {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 4px;
    width: 0;
    height: 2px;
    background: #D92499;
    border-radius: 3px;
    -webkit-transform-origin: center left;
            transform-origin: center left;
    transition: .2s ease;
}

.categories-checkbox:before {
    -webkit-transform: rotate(57deg);
            transform: rotate(57deg);
    left: 7px;
    bottom: 13px;
    transition-delay: .18s;
}

.categories-checkbox:after {
    -webkit-transform: rotate(-58deg);
            transform: rotate(-58deg);
    transition-delay: 0ms;
}

.categories-checkbox:checked {
    background: #fff;
    box-shadow: inset 0 0 0 1px #fff;
    color: #fff;
}

.categories-checkbox:checked:before {
    width: 11px;
    transition-delay: 0ms;
}

.categories-checkbox:checked:after {
    width: 15px;
    transition-delay: .18s;
}

.categories .popup_btn--join {
    /* background: #FFB800; */
    margin-top: 40px;
    /* color: #070707; */
}

/* .categories .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.categories .popup_btn--join:hover::after {
    background-image: url('../../../img/btn-arr_black.svg');
}

.categories .popup_btn--join::after {
    background-image: url('../../../img/btn-arr_black.svg');
} */

@media screen and (max-width: 1200px) {
    .categories {
        flex-direction: column;
    }

    .categories-head {
        margin-right: 0;
        max-width: unset;
        width: 100%;
        margin-bottom: 30px;
    }

    .categories-title {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .main-categories_title {
        font-size: 1rem;
        margin-bottom: 20px;
    }

    .categories-item {
        padding: 5px 14px;
    }
}
.popup-overlay {
    background: rgba(23, 22, 22, .5);
    -webkit-backdrop-filter: blur(2.5px);
            backdrop-filter: blur(2.5px);
}

.modal {
    width: 547px;
    height: auto;
    max-height: calc(100vh - 200px);
    padding: 40px;
    background-image: url(/static/media/popup-bg.cb941112.png);
    background-size: cover;
    background-position: center;
    overflow-y: scroll;
    padding: 40px;
}

.popup-content .close {
    display: block;
    position: absolute;
    right: 8px;
    top: 12px;
    z-index: 10;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.packages-content_body {
    padding: 45px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packages-btn {
    border: none;
    box-shadow: none;
}

.packages-form {
    width: 100%;
    margin-bottom: 30px;
}

.packages-form_block {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.packages-form_input {
    margin-top: 25px;
    color: #fff;
}

.packages-form_input:focus {
    border-color: #fff;
}

.packages-form_input:nth-child(1) {
    margin-top: 0;
}

.packages-checkbox_label {
    line-height: 1.1;
    max-width: 308px;
}

.packages-checkbox_label a {
    color: #D92499;
    line-height: 1.1;
}

.packages-checkbox_label a:hover {
    color: #fff;
}

.form-error {
    color: #ff0000;
    margin-top: 7px;
    line-height: 1;
}

.packages-btn_block {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.packages-btn_block::after {
    content: '';
    position: absolute;
    right: 35px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url(/static/media/btn-arr.6b604341.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.packages-btn_block:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.button.packages-btn.packages-btn_unactive {
    opacity: .3;
}

.button.packages-btn.packages-btn_unactive:hover {
    opacity: .3;
}

.packages-form_input.error {
    border-color: #ff0000;
}

.packages-sales {
    font-weight: 700;
    margin-bottom: 10px;
}

.modal.modal--join{
    opacity: 1;
    transition: all ease-in-out .3s;
}
.modal.modal--join .packages-title{
    margin-bottom: 30px;
}
.modal.modal--join.success-modal{
    position: absolute;
    top: 0;
    z-index: -1;
    transition: all ease-in-out .3s;
}

.popup-content.success .modal.modal--join{
    opacity: 0;
}
.popup-content.success .modal.modal--join.success-modal{
    opacity: 1;
}

@media screen and (max-height: 900px) {
    .modal {
        max-height: calc(100vh - 70px);
    }
}

@media screen and (max-width: 1450px) {
    .packages-form {
        max-width: unset;
    }

    .packages-content_body {
        padding: 45px 35px;
    }
}

@media screen and (max-width: 1200px) {
    .modal {
        width: calc(100vw - 100px);
    }
}

@media screen and (max-width: 768px) {
    .packages-content_body {
        padding: 25px;
    }

    .modal {
        width: 100vw;
        max-height: calc(100vh - 70px);
        padding: 30px 20px;
    }
}
.packages-wrap_main .packages {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    justify-content: space-between;
}

.packages-wrap_main .packages-card {
    background: #1B1B1B;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.packages-wrap_main .packages-card:nth-child(1) {
    margin-right: 40px;
    max-width: 360px;
    width: 100%;
}

.packages-wrap_main .packages-card-wrap {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.packages-wrap_main .packages-card-wrap img {
    height: 234px;
    max-width: 380px;
    width: 100%;
    object-fit: cover;
}

.packages-wrap_main .packages-card-wrap .packages-card {
    border-radius: 0;
}

.packages-wrap_main .packages-btn {
    margin-top: auto;
}

.packages-wrap_main .packages-card .popup_btn--join {
    margin-top: auto;
}

.packages-wrap_main .packages-title {
    margin-bottom: 10px;
}

.packages-wrap_main .packages-desc {
    line-height: 1.1;
    margin-bottom: 20px;
    max-width: 300px;
}

.packages-wrap_main .packages-desc span {
    font-weight: 700;
    color: #fff;
}

@media screen and (max-width: 1450px) {
    .packages-wrap_main .packages-card-wrap img {
        height: 251px;
        max-width: 260px;
    }
}

@media screen and (max-width: 1200px) {
    .packages-wrap_main .packages-card-wrap img {
        height: 100%;
        max-width: unset;
        width: auto;
    }

    .packages-wrap_main .packages-card:nth-child(1) {
        max-width: unset;
        width: auto;
    }

    .packages-wrap_main .packages-card-wrap {
        width: auto;
    }
}

@media screen and (max-width: 992px) {
    .packages-wrap_main .packages {
        flex-direction: column;
    }

    .packages-wrap_main .packages-card:nth-child(1) {
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .packages-wrap_main .packages-card {
        background: transparent;
        padding: 0;
    }

    .packages-wrap_main .packages-card-wrap {
        flex-direction: column;
    }

    .packages-wrap_main .packages-card {
        background: #1B1B1B;
        border-radius: 5px;
        padding: 40px 12px 40px;
    }

    .packages-wrap_main .packages-card-wrap img {
        height: 170px;
        position: relative;
    }

    .packages-wrap_main .packages-card-wrap .packages-btn {
        margin-left: auto;
        margin-right: auto;
    }

    .packages-wrap_main .packages-card-wrap .packages-title {
        /* margin-top: 15px; */
        margin-bottom: 15px;
    }
}
.inspiration {
    display: flex;
    justify-content: space-between;
    /* border-top: 1px solid rgba(255, 255, 255, 0.15); */
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
}

.inspiration-gif {
    max-width: 600px;
    width: 100%;
    height: auto;
    object-fit: contain;
}

.inspiration-title {
    font-weight: 700;
    font-size: 46px;
    line-height: 123.5%;
}

.inspiration-desc {
    line-height: 1.1;
    margin-top: 10px;
}
.inspiration-desc .strong{
    font-weight: 600;
    color: #fff;
}

.inspiration-head-wrap {
    max-width: 440px;
}

.inspiration-content-mob{
    display: none;
}

.inspiration-head {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 13px;
    position: relative;
}

.inspiration-head::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #D92499;
    border-radius: 15px;
}

.incubator-inspiration .inspiration-head {
    max-width: 390px;
    margin-bottom: 30px;
}

.incubator-inspiration .inspiration-content{
    width: 45%;
}

.incubator-inspiration .inspiration-desc{
    line-height: 1.3;
}

@media screen and (max-width: 1450px) {
    .inspiration-title {
        font-size: 36px;
    }

    .inspiration-head {
        max-width: 280px;
    }
}

@media screen and (max-width: 1200px) {
    .inspiration-head-wrap {
        margin-right: 40px;
        max-width: 350px;
    }
}

@media screen and (max-width: 992px) {
    .inspiration {
        flex-direction: column;
    }

    .inspiration-head-wrap {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    .inspiration {
        overflow-x: hidden;
        margin-left: 0;
        margin-right: 0;
        padding-right: 10px;
        padding-left: 10px;
        max-width: 100% !important;
    }

    .inspiration-gif {
        max-width: unset;
    }

    .inspiration-head-wrap {
        max-width: 500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 576px) {
    .inspiration-gif {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    .inspiration-head-wrap {
        max-width: unset;
        margin-left: 10px;
        margin-right: 10px;
    }

    .inspiration-head-wrap {
        margin-left: 0;
        margin-right: 0;
    }

    .inspiration-head-wrap {
        margin-bottom: 40px;
        max-width: 370px;
    }

    .inspiration-title {
        font-size: 26px;
    }
    
    .inspiration-content{
        display: none;
    }
    .inspiration-content-mob{
        display: block;
        margin: 20px auto;
    }
}
.forInvestors{
    overflow: hidden;
    position: relative;
}

.inspiration {
    display: flex;
    justify-content: space-between;
    /* border-top: 1px solid rgba(255, 255, 255, 0.15); */
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
}

.inspiration-gif {
    max-width: 600px;
    width: 100%;
    height: auto;
    object-fit: contain;
}

.inspiration-title {
    font-weight: 700;
    font-size: 46px;
    line-height: 123.5%;
}

.forInvestors .inspiration-head::before{
    background: #1a9317;
}

.forInvestors .text-colorful{
    color: #1a9317;
}

.forInvestors .button{
    background: #1a9317;
}
.forInvestors .button:hover{
    background: #fff;
    color: #070707;
}

.inspiration-desc {
    line-height: 1.1;
    margin-top: 10px;
}

.inspiration-head-wrap {
    max-width: 440px;
}

.inspiration-head {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 13px;
    position: relative;
}

.inspiration-head::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #D92499;
    border-radius: 15px;
}
.forInvestors::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 50%;
    width: 500px;
    height: 500px;
    background: #1A9317;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0) translate(0, 90%);
            transform: translate3d(0, 0, 0) translate(0, 90%);
}

.forInvestors::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    -webkit-transform: translate(0, -50%) translate3d(0, 0, 0);
            transform: translate(0, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #1A9317;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
}

.inspiration-btn{
    margin: 20px 0;
}

@media screen and (max-width: 1450px) {
    .inspiration-title {
        font-size: 36px;
    }

    .inspiration-head {
        max-width: 280px;
    }
}

@media screen and (max-width: 1200px) {
    .inspiration-head-wrap {
        margin-right: 40px;
        max-width: 350px;
    }
}

@media screen and (max-width: 992px) {
    .inspiration {
        flex-direction: column;
    }

    .inspiration-head-wrap {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    .inspiration {
        overflow-x: hidden;
        margin-left: 0;
        margin-right: 0;
        padding-right: 10px;
        padding-left: 10px;
        max-width: 100% !important;
    }

    .inspiration-gif {
        max-width: unset;
    }

    .inspiration-head-wrap {
        max-width: 500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 576px) {
    .inspiration-gif {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    .inspiration-head-wrap {
        max-width: unset;
        margin-left: 10px;
        margin-right: 10px;
    }

    .inspiration-head-wrap {
        margin-left: 0;
        margin-right: 0;
    }

    .inspiration-head-wrap {
        margin-bottom: 40px;
        max-width: 100%;
    }

    .inspiration-title {
        font-size: 26px;
    }
}
.main-wrap {
    position: relative;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: clip;
}

.main-wrap::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 655px;
    height: 540px;
    background-image: url(/static/media/design.33519c7d.png);
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -2;
}

.main-wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -140px;
    width: 500px;
    height: 500px;
    background: rgba(217, 36, 153, 0.5);
    -webkit-filter: blur(300px);
            filter: blur(300px);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    padding-top: 50px;
}

.main-title{
    font-weight: 700;
}
.main-subtitle{
    font-size: 40px;
    font-weight: 700;
}

.main-title span {
    margin-right: auto;
}

.main-item {
    width: 50%;
}

.main-desc {
    max-width: 570px;
    line-height: 1.1;
    font-weight: 300;
    font-size: 28px;
    /* margin-bottom: 50px; */
}

.main-wrap button.button.popup_btn--join {
    margin-top: 40px;
}

/* 
.main-wrap button.button.popup_btn--join {
    margin-top: 40px;
    background: #fff;
    color: #070707;
}

.main-wrap button.button.popup_btn--join:hover {
    background: #D92499;
    color: #fff;
}

.main-wrap button.button.popup_btn--join:hover::after {
    background-image: url('../../../img/btn-arr.svg');
}

.main-wrap button.button.popup_btn--join::after {
    background-image: url('../../../img/btn-arr_black.svg');
} */

.main-img {
    -webkit-transform: scale(1.1) translate(-90px, 62px);
            transform: scale(1.1) translate(-90px, 62px);
}

.main-content {
    z-index: 1;
}

.main-img_mob {
    display: none;
}

@media screen and (max-width: 1450px) {
    .main-wrap::before {
        width: 515px;
        height: 450px;
    }

    .main {
        padding-top: 50px;
    }

    .main-desc {
        max-width: 510px;
        font-size: 24px;
    }
}

@media screen and (max-width: 1200px) {
    .main-img {
        -webkit-transform: scale(1.1) translate(-40px, 82px);
                transform: scale(1.1) translate(-40px, 82px);
    }
}

@media screen and (max-width: 992px) {
    .main {
        flex-direction: column;
    }

    .main-item {
        width: 100%;
    }

    .main-img {
        -webkit-transform: unset;
                transform: unset;
    }

    .main-img_pc {
        display: none;
    }

    .main-img_block {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    .main-img_mob {
        order: 1;
        display: block;
    }

    .main-wrap::after {
        bottom: unset;
        top: 0;
    }

    .main-content {
        margin-bottom: 0;
        order: 2;
    }

    .main-wrap {
        min-height: unset;
        height: 100%;
    }
}

@media screen and (max-width: 576px) {
    .main-wrap::after {
        width: 310px;
        height: 310px;
    }

    .main-wrap::before {
        width: 385px;
        height: 410px;
        -webkit-transform: scale(1.2) translateX(80px);
                transform: scale(1.2) translateX(80px);
    }

    .main-desc {
        max-width: 320px;
        font-size: 22px;
    }
}
.sf {
    display: flex;
}

.sf-wrap {
    background: #000;
}

.sf-head {
    margin-right: 50px;
    max-width: 203px;
    width: 100%;
}

.sf-content {
    display: flex;
    flex-wrap: wrap;
}

.sf-logo:nth-child(4n) {
    padding-right: 0;
}

.sf-logo:nth-child(4n + 1) {
    padding-left: 0;
}

.sf-logo {
    width: calc(100% / 4);
    padding: 5px 42px;
    object-fit: contain;
    object-position: center;
}

.sf-content_mob {
    display: none;
}

.scrolling-images-container {
    display: none;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.scrolling-images-container::-webkit-scrollbar {
    width: 4px;
}

.scrolling-images-container::-webkit-scrollbar-track {
    background: transparent;
}

.scrolling-images-container::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
}

.scrolling-image-wrapper {
    flex: 0 0 115px;
    margin-right: 10px;
    margin-left: 10px;
}

.scrolling-image-wrapper img {
    width: 100%;
    height: auto;
    max-height: 53px;
}

@media screen and (max-width: 1450px) {
    .sf-logo {
        padding: 5px 20px;
    }
}

@media screen and (max-width: 1200px) {
    .sf-head {
        margin-right: 0;
        max-width: unset;
        width: 100%;
        margin-bottom: 30px;
    }

    .sf {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .sf-content {
        display: none;
    }

    .scrolling-images-container {
        display: flex;
        align-items: center;
    }

    .container.container_padding.sf {
        margin-left: 0;
        margin-right: 0;
        max-width: 100% !important;
    }

    .sf-head {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .sf-head {
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px);
    }
}
.quote-wrap {
    position: relative;
    overflow-x: clip;
    overflow-y: clip;
}

.quote-wrap::after {
    content: '';
    position: absolute;
    right: -15%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #D92499;
    -webkit-filter: blur(170px);
            filter: blur(170px);
    z-index: -1;
}

.quote-wrap::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #D92499;
    -webkit-filter: blur(170px);
            filter: blur(170px);
    z-index: -1;
}

.quote {
    font-size: 96px;
    text-align: center;
    font-weight: 400;
}

.quote-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.quote-desc{
    text-align: center;
    font-style: italic;
}
.incubator-quote .quote-desc{
    font-size: 26px;
    font-weight: 300;
}

.quote-img {
    display: none;
}

@media screen and (max-width: 1800px) {

    .quote-wrap::after,
    .quote-wrap::before {
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 1450px) {
    .quote {
        font-size: 75px;
    }

    .quote-wrap::after,
    .quote-wrap::before {
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 1200px) {
    .quote {
        font-size: 63px;
    }
}

@media screen and (max-width: 992px) {
    /* .quote span {
        font-size: 135px;
    } */
    /* 
    .quote span:nth-child(2) {
        font-size: 26px;
        max-width: 160px;
        display: inline-block;
        text-align: left;
        width: 100%;
    } */

    .quote {
        font-size: 46px;
    }

    .quote-wrap::after {
        right: -15%;
        top: unset;
        -webkit-transform: translate(0, 50%) translate3d(0, 0, 0);
                transform: translate(0, 50%) translate3d(0, 0, 0);
        bottom: 0;
        width: 250px;
        height: 250px;
        -webkit-filter: blur(110px);
                filter: blur(110px);
    }

    .quote-wrap::before {
        left: 0%;
        top: 0;
        -webkit-transform: translate(0, -50%) translate3d(0, 0, 0);
                transform: translate(0, -50%) translate3d(0, 0, 0);
        width: 250px;
        height: 250px;
        -webkit-filter: blur(110px);
                filter: blur(110px);
    }
}

@media screen and (max-width: 768px) {
    .quote {
        font-size: 34px;
    }
}

@media screen and (max-width: 576px) {
    .incubator-page .quote {
        display: block;
    }

    .quote-img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .quote-wrap::after,
    .quote-wrap::before {
        width: 200px;
        height: 200px;
        -webkit-filter: blur(100px);
                filter: blur(100px);
    }
}
/* new calendar items */
.calendar{
    margin: 0rem auto 5rem;
}

.calendar-items{
    display: flex;
    flex-direction: column;
}
.calendar-item{
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    border-top: 1px solid #282828;
    padding: 18px 25px 9px;
    font-size: 26px;
}
.calendar-item:last-child{
    border-bottom: 1px solid #282828;
}
.calendar-item_times{
    display: grid;
    grid-template-columns: 150px 150px;
}
.calendar-item_times__date{
    color: #f6f6f6;
}
.calendar-item_times__date span{
    color: #ababab;
}
.calendar-item_times__time{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #ababab;
}
.calendar-item_times__time::before{
    content: '';
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #04690E;
}
.calendar-item_information{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

/* END new calendar items */

.calendar-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.calendar-tabs {
    display: flex;
}

.calendar-tab {
    margin-right: 15px;
    cursor: pointer;
}

/* .calendar-tab:hover {
    color: #D92499;
} */

.calendar-tab.active {
    position: relative;
}

.calendar-tab.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    height: 4px;
    border-radius: 15px;
    background: #D92499;
}

.calendar-tab:last-of-type {
    margin-right: 0;
}

.calendar-title {
    margin-bottom: 10px;
}

.calendar-desc {
    margin-bottom: 30px;
    line-height: 1.15;
}

.calendar-dropdown {
    position: relative;
}

.calendar-header {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.calendar-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #070707;
    border: 1px solid #fff;
    border-radius: 5px;
    z-index: 1;
}

.calendar-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.calendar-content li {
    padding: 10px;
    cursor: pointer;
}

.calendar-content li:hover {
    color: #D92499;
}

.calendar-arrow {
    width: 19px;
    height: 1px;
    position: relative;
    margin-left: 5px;
}

.calendar-arrow::before,
.calendar-arrow::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 1px;
    background-color: #fff;
}

.calendar-arrow::before {
    top: 0;
    left: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.calendar-arrow::after {
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.open .calendar-arrow {
    -webkit-transform: translateY(-50%) rotate(-180deg);
            transform: translateY(-50%) rotate(-180deg);
}

/*Month*/

.calendar-month_title {
    line-height: 1;
    text-align: center;
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.calendar-month:first-of-type .calendar-month_title {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

/*Day*/

.calendar-day {
    display: flex;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.calendar-day_head {
    width: 150px;
    padding-right: 15px;
}

.calendar-day_pic {
    width: 124px;
    height: auto;
    object-fit: contain;
    margin-right: 30px;
    border-radius: 5px;
}

.calendar-day_content-desc {
    line-height: 1.1;
}

.calendar-btn {
    margin-top: 50px;
}

.calendar-day:hover {
    background: rgba(217, 36, 153, 0.1);
}

.calendar-img--mob {
    display: none;
}

.calendar-img {
    width: 100%;
    object-fit: contain;
    height: auto;
}

@media screen and (max-width: 992px) {
    .calendar-day_head {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    /* new calendar items */
    .calendar-item{
        padding: 20px 0px 18px;
        grid-gap: 0px;
        line-height: 1.3em;
    }
    .calendar-item_times{
        grid-template-columns: 150px;
    }
    .calendar-item_times__time::before{
        display: none;
    }
    .calendar-item_information{
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    /* END new calendar items */

    .calendar-day_pic {
        width: 53px;
        margin-right: 10px;
    }

    .calendar-day {
        align-items: flex-start;
    }

    .calendar-day_content {
        display: flex;
        flex-direction: column;
    }

    .calendar-day_content-title {
        order: 2;
    }

    .calendar-day_content-desc {
        order: 1;
        margin-bottom: 6px;
    }

    .calendar-img--mob {
        display: block;
    }

    .calendar-img--pc {
        display: none;
    }
}
@media screen and (max-width: 450px) {
    /* new calendar items */
    .calendar-item{
        font-size: 18px;
        line-height: 1.2em;
    }
    .calendar-item_times{
        grid-template-columns: 120px;
    }
    .calendar-item_information{
        grid-template-columns: 50px 1fr;
        grid-gap: 10px;
    }

    /* END new calendar items */
}
.incubator {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.incubator-wrap {
    position: relative;
    background: #fee6ff;
}

.incubator-card_content {
    padding: 15px;
}

.incubator-title {
    color: #2d712a;
    margin-bottom: 10px;
}

.incubator-desc {
    margin-bottom: 30px;
    line-height: 1.1;
    color: #2d712a;
}

.incubator-content {
    display: flex;
    flex-wrap: wrap;
}

.incubator-card {
    width: calc((100% / 5) - 16px);
    margin-right: 20px;
    margin-bottom: 20px;
    background: #141414;
    border-radius: 5px;
    overflow: hidden;
}

.incubator-card:last-of-type {
    margin-right: 0;
}

.incubator-card_btn:hover span {
    color: #fff;
}

.incubator-card_btn:hover path {
    fill: #fff;
}

.incubator-card_img {
    width: 100%;
    object-fit: cover;
}

.incubator-card_btn {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.incubator-card_btn span {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: underline;
    color: #D92499;
}

.incubator-card_btn svg {
    width: 13px !important;
    margin-left: 8px;
}

.incubator-card .popup_btn--join {
    margin-top: 18px;
    line-height: 1;
    padding: 0;
    background: transparent;
    height: unset;
    color: #2d712a;
    text-decoration: underline;
}

.incubator-card .popup_btn--join:hover {
    color: #fff;
}

.incubator-card {
    transition: .3s;
}

.incubator-card:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

.incubator-card .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_yellow.312e6a81.svg);
    right: -17px;
}

.incubator-card .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr.6b604341.svg);
}

.incubator-motivation_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.incubator-motivation_img {
    width: 191px;
    margin-right: 100px;
    -webkit-transform: rotate(17deg) translateY(20px);
            transform: rotate(17deg) translateY(20px);
}

.incubator-motivation_title {
    position: relative;
    font-weight: 700;
    font-size: 36px;
    color: #2d712a;
    padding-left: 10px;
    margin-bottom: 10px;
}

.incubator-motivation_title span {
    color: #2d712a;
}

.incubator-motivation_title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #2d712a;
    border-radius: 15px;
}

.incubator-motivation_desc {
    line-height: 1.1;
    color: #2d712a;
}

.incubator-btn_wrap .popup_btn--join {
    background: #2d712a;
    margin: 20px 20px 20px 0;
    color: #fff;
}

.incubator-btn_wrap .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.incubator-btn_wrap .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.incubator-btn_wrap .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_white.79137ebf.svg);
}

.incubator-card_title{
    margin: 5px 0 10px;
}

.incubator-link {
    border: 1px solid #2d712a;
    background: transparent;
    padding: 0 59px;
    margin-top: 20px;
    color: #2d712a;
}
.incubator-link::after {
    display: none;
}

.incubator-link:hover {
    background: #2d712a;
    color: #fff;
}

.incubator-link{
    border: 1px solid #2d712a;
    background: transparent;
    margin-top: 20px;
    color: #2d712a;
}

.incubator-link:hover{
    background: #2d712a;
    color: #fff;
}

@media screen and (max-width: 1450px) {
    .incubator-motivation_img {
        margin-right: 60px;
    }

    .incubator-wrap::after {
        width: 370px;
        height: 370px;
        -webkit-filter: blur(300px);
                filter: blur(300px);
    }
}

@media screen and (max-width: 1200px) {
    .incubator-card {
        width: calc((100% / 3) - 20px);
    }

    .incubator-card:nth-child(3n) {
        margin-right: 0;
    }

    .incubator-wrap::after {
        width: 270px;
        height: 270px;
        -webkit-filter: blur(200px);
                filter: blur(200px);
    }
}

@media screen and (max-width: 992px) {
    .incubator-motivation_img {
        position: absolute;
        right: 50px;
        top: 70px;
        width: 141px;
        height: 141px;
        margin-right: 0;
    }

    .container.container_padding.incubator {
        padding-top: 80px;
    }

    .incubator-motivation_block {
        margin-bottom: 70px;
    }

    .incubator-card {
        width: calc((100% / 2) - 10px);
    }

    .incubator-card:nth-child(3n) {
        margin-right: 20px;
    }

    .incubator-card:nth-child(2n) {
        margin-right: 0px;
    }

    .incubator-desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 768px) {
    .incubator-motivation_img {
        top: 40px;
    }

    .incubator-motivation_desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 576px) {
    .incubator-card {
        width: calc((100% / 2) - 10px);
        /* margin-right: 0; */
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
    }

    .incubator-motivation_title {
        font-size: 26px;
    }

    .incubator-motivation_img {
        right: unset;
        left: 170px;
        top: 67px;
        width: 100px;
        height: 100px;
    }

    .incubator-wrap::after {
        width: 130px;
        height: 130px;
        -webkit-filter: blur(90px);
                filter: blur(90px);
        top: 0%;
        right: -28%;
    }
}
@media screen and (max-width: 480px) {
    .incubator-btn_wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .incubator-btn_wrap .popup_btn--join{
        margin: 0;
    }
}
.practices {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.container.container_padding.practices.forStartuppers {
    padding-top: 80px;
}

.forStartuppers .practices-motivation_block{
    margin-bottom: 50px;
}
.practices-wrap {
    position: relative;
}

.practices-wrap::after {
    content: '';
    position: absolute;
    right: -15%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
    width: 470px;
    height: 470px;
    background: #FFB800;
    -webkit-filter: blur(350px);
            filter: blur(350px);
    z-index: -1;
}

.practices-card_content {
    padding: 15px;
}

.practices-title {
    margin-bottom: 10px;
}

.practices-desc {
    margin-bottom: 30px;
    line-height: 1.1;
}

.practices-content {
    display: flex;
    flex-wrap: wrap;
}

.practices-card {
    width: calc((100% / 4) - 15px);
    margin-right: 20px;
    margin-bottom: 20px;
    background: #141414;
    border-radius: 5px;
    overflow: hidden;
}

.practices-card:last-of-type {
    margin-right: 0;
}

.practices-card_btn:hover span {
    color: #fff;
}

.practices-card_btn:hover path {
    fill: #fff;
}

.practices-card_img {
    width: 100%;
    object-fit: cover;
}

.practices-card_btn {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.practices-card_btn span {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: underline;
    color: #D92499;
}

.practices-card_btn svg {
    width: 13px !important;
    margin-left: 8px;
}

.practices-card .popup_btn--join {
    margin-top: 18px;
    line-height: 1;
    padding: 0;
    background: transparent;
    height: unset;
    color: #FFB800;
    text-decoration: underline;
}

.practices-card .popup_btn--join:hover {
    color: #fff;
}

.practices-card {
    transition: .3s;
}

.practices-card:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

.practices-card .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_yellow.312e6a81.svg);
    right: -17px;
}

.practices-card .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr.6b604341.svg);
}

.practices-motivation_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.practices-motivation_img {
    width: 191px;
    margin-right: 100px;
    -webkit-transform: rotate(17deg) translateY(20px);
            transform: rotate(17deg) translateY(20px);
}

.practices-motivation_title {
    font-weight: 700;
    font-size: 36px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 10px;
}

.practices-motivation_title span {
    color: #FFB800;
}

.practices-motivation_title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #FFB800;
    border-radius: 15px;
}

.practices-motivation_desc {
    line-height: 1.1;
}

.practices-btn_wrap .popup_btn--join {
    background: #FFB800;
    margin-top: 20px;
    color: #070707;
}

.practices-btn_wrap .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.practices-btn_wrap .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.practices-btn_wrap .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

@media screen and (max-width: 1450px) {
    .practices-motivation_img {
        margin-right: 60px;
    }

    .practices-wrap::after {
        width: 370px;
        height: 370px;
        -webkit-filter: blur(300px);
                filter: blur(300px);
    }
}

@media screen and (max-width: 1200px) {
    .practices-card {
        width: calc((100% / 3) - 20px);
    }

    .practices-card:nth-child(3n) {
        margin-right: 0;
    }

    .practices-wrap::after {
        width: 270px;
        height: 270px;
        -webkit-filter: blur(200px);
                filter: blur(200px);
    }
}

@media screen and (max-width: 992px) {
    .practices-motivation_img {
        position: absolute;
        right: 50px;
        top: 70px;
        width: 141px;
        height: 141px;
        margin-right: 0;
    }

    .container.container_padding.practices {
        padding-top: 80px;
    }

    .practices-motivation_block {
        margin-bottom: 70px;
    }

    .practices-card {
        width: calc((100% / 2) - 10px);
    }

    .practices-card:nth-child(3n) {
        margin-right: 20px;
    }

    .practices-card:nth-child(2n) {
        margin-right: 0px;
    }

    .practices-desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 768px) {
    .practices-motivation_img {
        top: 40px;
    }

    .practices-motivation_desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 576px) {
    .practices-card {
        width: calc((100% / 2) - 10px);
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
    }

    .practices-motivation_title {
        font-size: 26px;
    }

    .practices-motivation_img {
        right: unset;
        left: 170px;
        top: 67px;
        width: 100px;
        height: 100px;
    }

    .practices-wrap::after {
        width: 130px;
        height: 130px;
        -webkit-filter: blur(90px);
                filter: blur(90px);
        top: 0%;
        right: -28%;
    }
}
.prompts {
    display: flex;
    justify-content: space-between;
}

.prompts-wrap {
    background: #141414;
}

.prompts-head {
    margin-right: 64px;
}

.prompts-search-block-wrap {
    max-width: 675px;
}

.prompts-search-block {
    display: flex;
    align-items: center;
    /* border: 1px solid #3F3F3F; */
    border-radius: 5px;
    margin-bottom: 30px;
}

.prompts-search-btn {
    background: #FFB800;
    padding: 1.53px 0px;
    border-radius: 0 5px 5px 0;
}

.prompts-search-block svg {
    width: 21px;
    margin: 12px;
}

.prompts-search-block input {
    background: #fff;
    border: none;
    box-shadow: none;
    color: #070707;
    background-color: #fff;
    border-radius: 5px 0 0 5px;
}

.prompts-search-block input::-webkit-input-placeholder {
    color: #CBCBCB;
}

.prompts-search-block input:-ms-input-placeholder {
    color: #CBCBCB;
}

.prompts-search-block input::placeholder {
    color: #CBCBCB;
}

.prompts-card_head {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.prompts-card_head svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

/* .prompts-card_head svg:hover path {
    stroke: #000;
}

.prompts-card_head svg:hover rect {
    fill: #fff;
} */

.prompts-card_head svg:last-of-type {
    margin-right: 0;
}

.practices-card_tag {
    background: rgba(255, 184, 0, 0.25);
    border-radius: 5px;
    padding: 8px;
    margin-right: 32px;
}

.prompts-title {
    margin-bottom: 5px;
}

.prompts-card {
    margin-bottom: 30px;
}

.prompts-card:last-of-type {
    margin-bottom: 0;
}

.prompts-wrap .popup_btn--join {
    background: #FFB800;
    margin-top: 40px;
    color: #070707;
}

.prompts-wrap .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.prompts-wrap .popup_btn--join:hover::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.prompts-wrap .popup_btn--join::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.prompts-search-block-wrap .popup_btn--join {
    display: none;
}

@media screen and (max-width: 1200px) {
    .prompts {
        flex-direction: column;
    }

    .prompts-head {
        margin-bottom: 30px;
    }

    .prompts-search-block-wrap {
        max-width: unset;
    }

    .prompts-head .popup_btn--join {
        display: none;
    }

    .prompts-search-block-wrap .popup_btn--join {
        display: flex;
    }
}
.enjoy {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.enjoy-content {
    max-width: 600px;
}

.enjoy-title {
    margin-bottom: 10px;
}

.enjoy-desc {
    margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
    .enjoy {
        flex-direction: column;
    }

    .enjoy-head .popup_btn--join {
        display: none;
    }

    .enjoy-content {
        max-width: unset;
    }

    .enjoy-gif {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .enjoy-gif {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}

.userflow-wrapper {
    position: relative;
    overflow-y: clip;
    display: grid;
    min-height: 100dvh;
}

.userflow-wrapper::after,
.userflow-wrapper::before {
    content: '';
    position: absolute;
    width: 100vw;
    min-width: 300px;
    height: 22vh;
    min-height: 200px;
    background: rgba(217, 36, 153, 0.4);
    -webkit-filter: blur(200px);
            filter: blur(200px);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    z-index: -1;
}

.userflow-wrapper::after {
    top: 0%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
}

.userflow-wrapper::before {
    bottom: 0%;
    left: 50%;
    -webkit-transform: translate(-50%, 50%) translate3d(0, 0, 0);
            transform: translate(-50%, 50%) translate3d(0, 0, 0);
}

.userflow-design {
    position: absolute;
    top: -140px;
    right: 0;
    width: 480px;
    height: 480px;
    -webkit-transform: scale(2) rotate(-25deg);
            transform: scale(2) rotate(-25deg);
}

.userflow-wrap {
    display: none;
    /* padding-top: 50px; */
    align-self: stretch;
}

.userflow-wrap.active {
    display: grid;
}

.userflow {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.userflow-img {
    max-width: 555px;
    object-fit: contain;
    margin-bottom: 70px;
}

.userflow-title {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 10px;
}

.userflow-desc {
    color: #CBCBCB;
    max-width: 625px;
    line-height: 1.1;
    margin-bottom: 36px;
}

.userflow-arr {
    width: 9px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
}

.userflow-arr:hover path {
    stroke: #fff;
}

.userflow-arr_prev {
    left: 0;
}

.userflow-arr_next {
    right: 0;
}

.userflow-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    width: 43%;
}

.userflow-dot {
    width: 9px;
    min-width: 9px;
    height: 9px;
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    margin-right: 14px;
}

.userflow-dot.active {
    background: #fff;
}

.userflow-dot:last-of-type {
    margin-right: 0;
}

.button.userflow-btn.grey-btn {
    background: transparent;
    padding: 0;
    color: #7B7B7B;
}

.button.userflow-btn.grey-btn::after {
    background-image: url(/static/media/btn-arr_grey.6cb546e4.svg);
    right: -17px;
}

.button.userflow-btn.grey-btn:hover {
    color: #fff;
}

.button.userflow-btn.grey-btn:hover::after {
    background-image: url(/static/media/btn-arr.6b604341.svg);
}

.userflow-links {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.userflow-link {
    margin-right: 46px;
}

.userflow-link:last-of-type {
    margin-right: 0;
}

@media screen and (max-width: 1600px) {
    .userflow-img {
        max-width: 455px;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 992px) {
    .userflow-desc {
        margin-bottom: auto;
    }

    .userflow-dots {
        margin-top: 20px;
    }

    .userflow-title {
        margin-top: 40px;
    }

    .userflow-img {
        margin-bottom: auto;
    }

    .userflow-wrap {
        max-height: 820px;
    }
}

@media screen and (max-width: 768px) {
    .userflow-img {
        max-width: 400px;
        width: 100%;
    }

    .userflow-dots {
        width: 93%;
    }

    .userflow-wrap {
        padding-top: 50px;
    }

    .userflow-design {
        display: none;
    }

    .userflow-arr {
        width: 20px;
        height: 20px;
    }

    .userflow-wrapper::after,
    .userflow-wrapper::before {
        -webkit-filter: blur(110px);
                filter: blur(110px);
    }

    .userflow-title {
        font-size: 26px;
    }

    .userflow-desc {
        margin-bottom: 50px;
    }

    .userflow-dots {
        margin-bottom: 30px;
    }
}
.lab-main_wrap {
    background-image: url(/static/media/pm.92b80769.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.lab .footer_wrap::before {
    content: unset;
}

.lab-main {
    display: flex;
}

.container.container_padding.lab-main {
    padding-top: 170px;
    padding-bottom: 160px;
}

.lab-main_title {
    font-weight: 600;
}

.lab-main_desc {
    margin-bottom: 50px;
    max-width: 440px;
    line-height: 1.1;
}

.lab-main_content {
    margin-right: 150px;
}

.lab-main_btns .lab-main_btn {
    background: #fff;
    color: #070707;
    margin-right: 43px;
}

.lab-main_btns .lab-main_btn:hover {
    background: #070707;
    color: #fff;
}

.lab-main_btns .lab-main_btn::after {
    background-image: url(/static/media/btn-arr_black.8cb7d03d.svg);
}

.lab-main_btns .lab-main_btn:hover::after {
    background-image: url(/static/media/btn-arr.6b604341.svg);
}

.lab-main_btns a span {
    text-decoration: underline;
    margin-right: 5px;
}

.lab-main_btns a {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.lab-main_btns a svg {
    width: 12px;
}

.lab-main_btns a:hover {
    color: #070707;
}

.lab-main_btns a:hover path {
    fill: #070707;
}

.lab-main_btns {
    display: flex;
    align-items: center;
}

.lab-main_img-block img {
    max-width: 580px;
    object-fit: contain;
}

@media screen and (max-width: 1450px) {
    .lab-main_img-block img {
        max-width: 520px;
    }

    .lab-main_content {
        margin-right: 80px;
    }

    .container.container_padding.lab-main {
        padding-bottom: 120px;
    }
}

@media screen and (max-width: 1200px) {
    .lab-main_img-block img {
        max-width: 420px;
    }

    .lab-main_desc {
        max-width: 310px;
    }
}

@media screen and (max-width: 992px) {
    .lab-main {
        display: flex;
        flex-direction: column;
    }

    .lab-main_content {
        margin-right: 0;
        order: 2;
        position: relative;
        top: -40px;
        z-index: 1;
    }

    .lab-main_img-block {
        order: 1;
    }

    h1 {
        font-size: 76px;
    }

    .lab-main_desc {
        max-width: 440px;
    }

    .container.container_padding.lab-main {
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 576px) {
    .lab-main_img-block img {
        max-width: unset;
        width: 100%;
    }

    h1 {
        font-size: 56px;
    }
}

@media screen and (max-width: 460px) {
    .lab-main_desc {
        max-width: 310px;
    }
}

@media screen and (max-width: 370px) {
    h1 {
        font-size: 50px;
    }

    .lab-main_btns .lab-main_btn {
        margin-right: 23px;
    }
}
.lab-descr_title {
    margin-left: 15px;
    position: relative;
    margin-bottom: 30px;
}

.lab-descr_title::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    width: 3px;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(176deg, #FFC130 0%, #EF6F96 100%);
}

.lab-desc_content_block {
    display: flex;
}

.lab-descr_title {
    font-size: 36px;
}

.lab-desc_content {
    margin-right: 30px;
}

.lab-desc_img-block img {
    max-width: 706px;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.lab-desc_content {
    color: #CBCBCB;
}

.lab-desc_content li span {
    font-weight: 700;
    color: #fff;
}

.lab-desc_content p {
    margin-bottom: 20px;
}

.lab-desc_content li {
    margin-bottom: 20px;
}

.lab-desc_btn {
    margin-top: 40px;
}

@media screen and (max-width: 1450px) {
    .lab-desc_img-block img {
        max-width: 586px;
    }
}

@media screen and (max-width: 1200px) {
    .lab-desc_content_block {
        flex-direction: column;
    }

    .lab-descr_title {
        margin-bottom: 10px;
    }

    .lab-desc_img-block img {
        max-width: unset;
        width: 100%;
        height: 400px;
    }

    .lab-desc_btn {
        margin-top: 50px;
    }

    .lab-descr_title {
        font-size: 26px;
    }
}

@media screen and (max-width: 992px) {
    .lab-desc_img-block img {
        height: 300px;
    }

    .lab-descr_title b {
        max-width: 180px;
        width: 100%;
        display: block;
    }
}

@media screen and (max-width: 576px) {
    .lab-desc_img-block img {
        height: auto;
        object-position: center;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    .container-labs.container_padding.lab-descr {
        padding-bottom: 180px;
    }
}
.lab-discord_wrap {
    overflow: hidden;
}

.lab-discord_creative {
    position: absolute;
    right: 25vw;
    top: 0;
    -webkit-transform: translateY(-40%);
            transform: translateY(-40%);
    object-fit: contain;
    width: 628px;
    height: auto;
}

.lab-discord_wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 50%;
    width: 400px;
    height: 400px;
    background: #FFB800;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0) translate(0, 90%);
            transform: translate3d(0, 0, 0) translate(0, 90%);
}

.lab-discord_wrap::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    -webkit-transform: translate(0, -50%) translate3d(0, 0, 0);
            transform: translate(0, -50%) translate3d(0, 0, 0);
    width: 400px;
    height: 400px;
    background: #FFB800;
    -webkit-filter: blur(300px);
            filter: blur(300px);
    z-index: -1;
}

.lab-discord_title {
    font-weight: 700;
    font-size: 36px !important;
    max-width: 570px;
}

.lab-discord_title span {
    background: -webkit-linear-gradient(176deg, #FFC130 0%, #EF6F96 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container-labs.container_padding.lab-discord {
    padding: 40px 0;
}

.lab-discord_design {
    position: absolute;
    top: 50%;
    right: -100px;
    -webkit-transform: translateY(-39%) rotate(6deg);
            transform: translateY(-39%) rotate(6deg);
    z-index: -1;
    max-height: 1080px;
}

@media screen and (max-width: 2100px) {
    .lab-discord_creative {
        right: 16vw;
    }
}

@media screen and (max-width: 1600px) {

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 360px;
        height: 360px;
        -webkit-filter: blur(250px);
                filter: blur(250px);
    }

    .lab-discord_creative {
        width: 548px;
    }
}

@media screen and (max-width: 1450px) {
    .lab-discord_creative {
        right: 60px;
    }

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 290px;
        height: 290px;
        -webkit-filter: blur(170px);
                filter: blur(170px);
    }
}

@media screen and (max-width: 1200px) {
    .lab-discord_title {
        font-size: 34px !important;
        max-width: 470px;
    }

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 260px;
        height: 260px;
        -webkit-filter: blur(160px);
                filter: blur(160px);
    }

    .lab-discord_creative {
        right: 20px;
        width: 458px;
    }

    .lab-discord_design {
        right: -290px;
    }
}

@media screen and (max-width: 992px) {
    .container-labs.container_padding.lab-discord {
        padding-top: 120px;
    }

    .lab-discord_creative {
        right: unset;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        top: -100px;
    }

    .lab-discord_title {
        max-width: 540px;
    }

    .lab-discord_design {
        -webkit-transform: translateY(-29%) rotate(6deg);
                transform: translateY(-29%) rotate(6deg);
    }

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 210px;
        height: 260px;
        -webkit-filter: blur(140px);
                filter: blur(140px);
    }
}

@media screen and (max-width: 768px) {

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 180px;
        height: 180px;
        -webkit-filter: blur(110px);
                filter: blur(110px);
    }

    .lab-discord_wrap::before {
        top: 20%;
    }

    .lab-discord_wrap::after {
        bottom: 20%;
    }

    .lab-discord_creative {
        width: 378px;
        top: -80px;
    }
}

@media screen and (max-width: 576px) {
    .lab-discord_creative {
        width: 340px;
    }

    .container-labs.container_padding.lab-discord {
        padding-top: 100px;
    }

    .lab-discord_title {
        max-width: 343px;
    }
}
/* new calendar items */
.calendar-items{
    display: flex;
    flex-direction: column;
}
.calendar-item{
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    border-top: 1px solid #282828;
    padding: 18px 25px 9px;
    font-size: 26px;
}
.calendar-item:last-child{
    border-bottom: 1px solid #282828;
}
.calendar-item_times{
    display: grid;
    grid-template-columns: 150px 150px;
}
.calendar-item_times__date{
    color: #f6f6f6;
}
.calendar-item_times__date span{
    color: #ababab;
}
.calendar-item_times__time{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #ababab;
}
.calendar-item_times__time::before{
    content: '';
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #04690E;
}
.calendar-item_information{
    display: grid;
    /* grid-template-columns: 90px 1fr; */
    grid-template-columns: 1fr 90px;
    grid-gap: 20px;
}

/* END new calendar items */

.calendar-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.calendar-tabs {
    display: flex;
}

.calendar-tab {
    margin-right: 15px;
    cursor: pointer;
}

/* .calendar-tab:hover {
    color: #D92499;
} */

.calendar-tab.active {
    position: relative;
}

.calendar-tab.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    height: 4px;
    border-radius: 15px;
    background: #D92499;
}

.calendar-tab:last-of-type {
    margin-right: 0;
}

.calendar-title {
    margin-bottom: 10px;
}

.calendar-desc {
    margin-bottom: 30px;
    line-height: 1.15;
}

.calendar-dropdown {
    position: relative;
}

.calendar-header {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.calendar-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #070707;
    border: 1px solid #fff;
    border-radius: 5px;
    z-index: 1;
}

.calendar-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.calendar-content li {
    padding: 10px;
    cursor: pointer;
}

.calendar-content li:hover {
    color: #D92499;
}

.calendar-arrow {
    width: 19px;
    height: 1px;
    position: relative;
    margin-left: 5px;
}

.calendar-arrow::before,
.calendar-arrow::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 1px;
    background-color: #fff;
}

.calendar-arrow::before {
    top: 0;
    left: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.calendar-arrow::after {
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.open .calendar-arrow {
    -webkit-transform: translateY(-50%) rotate(-180deg);
            transform: translateY(-50%) rotate(-180deg);
}

/*Month*/

.calendar-month_title {
    line-height: 1;
    text-align: center;
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.calendar-month:first-of-type .calendar-month_title {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

/*Day*/

.calendar-day {
    display: flex;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.calendar-day_head {
    width: 150px;
    padding-right: 15px;
}

.calendar-day_pic {
    width: 124px;
    height: auto;
    object-fit: contain;
    margin-right: 30px;
    border-radius: 5px;
}

.calendar-day_content-desc {
    line-height: 1.1;
}

.calendar-btn {
    margin-top: 50px;
}

.calendar-day:hover {
    background: rgba(217, 36, 153, 0.1);
}

.calendar-img--mob {
    display: none;
}

.calendar-img {
    width: 100%;
    object-fit: contain;
    height: auto;
}

@media screen and (max-width: 992px) {
    .calendar-day_head {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    /* new calendar items */
    .calendar-item{
        padding: 20px 0px 18px;
        grid-gap: 0px;
        line-height: 1.3em;
    }
    .calendar-item_times{
        grid-template-columns: 150px;
    }
    .calendar-item_times__time::before{
        display: none;
    }
    .calendar-item_information{
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    /* END new calendar items */

    .calendar-day_pic {
        width: 53px;
        margin-right: 10px;
    }

    .calendar-day {
        align-items: flex-start;
    }

    .calendar-day_content {
        display: flex;
        flex-direction: column;
    }

    .calendar-day_content-title {
        order: 2;
    }

    .calendar-day_content-desc {
        order: 1;
        margin-bottom: 6px;
    }

    .calendar-img--mob {
        display: block;
    }

    .calendar-img--pc {
        display: none;
    }
}
@media screen and (max-width: 450px) {
    /* new calendar items */
    .calendar-item{
        font-size: 18px;
        line-height: 1.2em;
    }
    .calendar-item_times{
        grid-template-columns: 120px;
    }
    .calendar-item_information{
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    /* END new calendar items */
}
