div {
    position: relative;
}

.container {
    max-width: 1400px;
    margin: 5rem auto;
    padding: 0;
    width: 100%;
}

.container.container_padding {
    margin: 0 auto;
    padding: 80px 0;
}

.container-labs {
    max-width: 1400px;
    margin: 160px auto;
    padding: 0;
    width: 100%;
}

.container-labs.container_padding {
    margin: 0 auto;
    padding: 160px 0;
}

.container--full {
    margin: 100px auto;
}

h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 1.2;
    color: #fff;
    margin-bottom: 10px;
}

h2 {
    font-size: 28px;
    line-height: 1.2;
    color: #fff;
    font-weight: 400;
}

h3 {
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    color: #fff;
}

.p1 {
    font-size: 16px;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #D92499;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    padding: 0 73px 0 59px;
    font-weight: 300;
    font-size: 18px;
    width: fit-content;
    transition: .25s ease;
    width: fit-content;
    height: 49px;
    position: relative;
}

.button::after {
    content: '';
    position: absolute;
    right: 55px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url('./img/btn-arr.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.button:hover {
    background: #fff;
    color: #070707;
}

.button:hover::after {
    background-image: url('./img/btn-arr_black.svg');
}

.button.lab-btn {
    background: linear-gradient(176deg, #FFC130 0%, #EF6F96 100%);
    color: #070707;
}

.button.lab-btn:hover {
    background: linear-gradient(176deg, #fff 0%, #fff 100%);
}

.button.lab-btn::after {
    background-image: url('./img/btn-arr_black.svg');
}

iframe {
    border: none;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: #070707;
}

::-webkit-scrollbar-thumb {
    background-color: #D92499;
    border-radius: 15px;
}

@media screen and (max-width: 1450px) {
    .container-labs {
        max-width: 1100px;
    }

    .container {
        max-width: 1100px;
        padding: 0;
    }

    .container.container_padding {
        padding: 80px 0;
    }

    h1 {
        font-size: 52px;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 920px;
    }

    .container-labs {
        max-width: 920px;
    }

    h1 {
        font-size: 46px;
    }
}

@media screen and (max-width: 992px) {
    .container {
        max-width: 680px;
        padding: 0 10px;
    }

    .container-labs {
        max-width: 680px;
        padding: 0 10px;
    }

    h1 {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 500px;
    }

    .container-labs {
        max-width: 500px;
    }

    h1 {
        font-size: 48px;
    }

}

@media screen and (max-width: 576px) {
    .container {
        max-width: 100%;
    }

    .container.container_padding {
        padding: 70px 0;
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px) !important;
    }

    .container-labs {
        max-width: 100%;
        margin: 100px auto;
    }

    .container-labs.container_padding {
        padding: 100px 0;
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px) !important;
    }

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 18px;
    }
}

@media screen and (max-width: 420px) {
    h1 {
        font-size: 35px;
    }
}

@media screen and (max-width: 370px) {
    h1 {
        font-size: 33px;
    }
}