.prompts {
    display: flex;
    justify-content: space-between;
}

.prompts-wrap {
    background: #141414;
}

.prompts-head {
    margin-right: 64px;
}

.prompts-search-block-wrap {
    max-width: 675px;
}

.prompts-search-block {
    display: flex;
    align-items: center;
    /* border: 1px solid #3F3F3F; */
    border-radius: 5px;
    margin-bottom: 30px;
}

.prompts-search-btn {
    background: #FFB800;
    padding: 1.53px 0px;
    border-radius: 0 5px 5px 0;
}

.prompts-search-block svg {
    width: 21px;
    margin: 12px;
}

.prompts-search-block input {
    background: #fff;
    border: none;
    box-shadow: none;
    color: #070707;
    background-color: #fff;
    border-radius: 5px 0 0 5px;
}

.prompts-search-block input::placeholder {
    color: #CBCBCB;
}

.prompts-card_head {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.prompts-card_head svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

/* .prompts-card_head svg:hover path {
    stroke: #000;
}

.prompts-card_head svg:hover rect {
    fill: #fff;
} */

.prompts-card_head svg:last-of-type {
    margin-right: 0;
}

.practices-card_tag {
    background: rgba(255, 184, 0, 0.25);
    border-radius: 5px;
    padding: 8px;
    margin-right: 32px;
}

.prompts-title {
    margin-bottom: 5px;
}

.prompts-card {
    margin-bottom: 30px;
}

.prompts-card:last-of-type {
    margin-bottom: 0;
}

.prompts-wrap .popup_btn--join {
    background: #FFB800;
    margin-top: 40px;
    color: #070707;
}

.prompts-wrap .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.prompts-wrap .popup_btn--join:hover::after {
    background-image: url('../../../img/btn-arr_black.svg');
}

.prompts-wrap .popup_btn--join::after {
    background-image: url('../../../img/btn-arr_black.svg');
}

.prompts-search-block-wrap .popup_btn--join {
    display: none;
}

@media screen and (max-width: 1200px) {
    .prompts {
        flex-direction: column;
    }

    .prompts-head {
        margin-bottom: 30px;
    }

    .prompts-search-block-wrap {
        max-width: unset;
    }

    .prompts-head .popup_btn--join {
        display: none;
    }

    .prompts-search-block-wrap .popup_btn--join {
        display: flex;
    }
}