.packages-row {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 2px solid #282828;
    align-items: center;
}

.packages-item_top--btn_block {
    display: flex;
    width: calc(100% - 45px);
}

.packages-item_top--btn_block.two {
    width: 100%;
}

.packages {
    margin-top: 80px;
}

.packages-top.packages-row {
    align-items: stretch;
}

.packages-row_part {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 66%;
}

.packages-price_block--full .packages-price_title-block {
    display: flex;
    align-items: center;
}

.packages-price_block.packages-price_block--border {
    border-bottom: none;
}

.packages-top_title-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 300px;
}

.packages-top_reliable-list li {
    display: flex;
}

.packages-top_reliable-list svg {
    width: 16px;
    object-fit: contain;
    margin-right: 7px;
    position: relative;
    top: 6px;
}

.packages-top_title-block--inner {
    margin-bottom: 30px;
}

.packages-top_discount {
    max-width: 160px;
    height: auto;
    object-fit: contain;
}

.packages-top.packages-row {
    border-bottom: unset;
}

.packages-top_row-part {
    border-bottom: unset;
}

.packages-item {
    width: calc(100% / 5);
    padding: 16px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.packages-row:last-of-type {
    border-bottom: unset;
}

.packages-item_top {
    padding: 20px 0 0 0;
    flex-direction: column;
}

.packages-item_top-btn.button.mr {
    margin-right: 15px;
}

.packages-item_top.active {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.packages-item.active {
    background-color: #14161A;
}

.packages-top_title {
    font-size: 36px;
    text-align: left;
    /* margin-bottom: 12px; */
    font-weight: 700;
    padding-left: 10px;
}

.packages-top_title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: #D92499;
    border-radius: 10px;
    width: 3px;
    height: 36px;
}

.packages-top_title span {
    color: #D92499;
}

.packages-top_title-block-hr {
    height: 4px;
    width: 20px;
    background: #D92499;
    margin-top: 4px;
}

.packages-best_value {
    padding: 4px 12px;
    border-radius: 20px;
    background: #fff;
    color: #070707;
    top: -10px;
    position: relative;
}

.packages-item_top-title {
    font-weight: 600;
    color: #fff !important;
    text-transform: uppercase;
}

.packages-item_top-desc {
    margin-bottom: 24px;
}

.packages-item_text {
    color: #ADADAD;
    line-height: 1.2;
    display: flex;
    align-items: center;
}

.packages-item_text.active {
    color: #fff;
}

.packages-item_top-btn {
    min-width: unset;
    width: 100%;
    padding: 0 12px 0 12px;
    line-height: 1.1;
}

.packages-item_top-btn::after {
    right: 10px;
    content: none;
}

.packages-item_top.packages-item.active .packages-item_top-btn {
    border-color: #D92499;
    background: #D92499;
}

.packages-item_top.packages-item.active .packages-item_top-btn::after {
    right: 18px;
    background-image: url('../../img/btn-arr.svg');
}

.packages-item_price {
    color: #fff;
    /* padding: 16px 0; */
    font-weight: 400;
    font-size: 28px;
    position: relative;
}

.packages-row:nth-child(4) {
    border-top: 2px solid #282828;
}

.packages-price_block--full .packages-item {
    padding: 0 5px;
}

.packages-item_price.active {
    color: #fff;
}

.packages-item_price--full::after {
    content: '';
    position: absolute;
    top: 28px;
    left: 0;
    width: 100%;
    height: 3px;
    background: #949aa4;
    transform: rotate(168deg);
}

.packages-item_price.active::after {
    background: #fff;
}

.packages-btn-main {
    margin: 30px auto 0;
    display: flex;
    width: 362px;
    height: 44px;
}

.packages-wrap--mob {
    display: none;
}

.packages-item-title {
    text-transform: uppercase;
    font-weight: 400;
}

.packages-top_desc {
    text-align: left;
}

.packages-item_top.packages-item.colorful {
    background: rgba(217, 36, 153, 0.2);
    border-radius: 10px 10px 0 0;
}

.packages-item.colorful {
    background: rgba(217, 36, 153, 0.2);
}

.packages-row:last-of-type .packages-item.colorful {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.packages-item_full-price {
    color: #FFB800;
    position: relative;
}

.packages-item_full-price::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 1px;
    width: 100%;
    background: #FFB800;
}

.packages-price_prices .packages-item_prices {
    display: flex;
    flex-direction: column;
}

.packages-price_prices .packages-item-row{
    flex-direction: row;
    justify-content: space-evenly;
    padding: 16px 0px;
}
.packages-price_prices .packages-item-row span{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.packages-price_prices .packages-item_price {
    margin-bottom: 9px;
}

.packages-item_title {
    text-align: left;
    line-height: 1.1;
}

.packages-special_title-block {
    width: calc(100% / 5);
}

.packages-item .popup_btn--join {
    min-width: unset;
    width: 100%;
    padding: 0 12px 0 12px;
    line-height: 1.1;
}

.packages-item .popup_btn--join::after {
    content: unset;
}

@media screen and (max-width: 1450px) {
    .packages-row_part {
        width: 80%;
    }

    .packages-top_discount {
        max-width: 120px;
        margin-left: 10px;
    }

    .packages-item_price {
        font-size: 24px;
    }

    .packages-top_title-block {
        max-width: 190px;
        text-align: left;
    }

    .packages-top_reliable-list span {
        font-size: 16px;
    }

    .packages-top_reliable-list svg {
        top: 5px;
    }
}

@media screen and (max-width: 1200px) {
    .packages-wrap--pc {
        display: none;
    }

    .packages-top_title-block {
        max-width: unset;
    }

    .packages-wrap--mob {
        display: block;
    }

    .packages-item-title {
        font-size: 27px;
        line-height: 1.1;
    }

    .packages-item-row.packages-item-row_price-block {
        border-bottom: unset;
        padding: 0;
    }

    .packages-top_title-block {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .packages-top.packages-row {
        margin-bottom: 30px;
    }

    .packages-top_discount {
        margin: 25px 0;
        display: none;
    }

    .packages-top_title {
        font-size: 37px;
    }


    .packages-items {
        display: flex;
        flex-wrap: wrap;
    }

    .packages-item {
        width: calc((100% / 2) - 15px);
        flex-direction: column;
        background: #14161A;
        border-radius: 10px;
        padding: 20px 20px 4px;
        margin-bottom: 60px;
    }

    .packages-item:last-of-type {
        margin-bottom: 0;
    }

    .packages-item_top-btn {
        padding: 0 24px 0 12px;
    }

    .packages-item_top-btn::after {
        content: none;
    }

    .packages-item:nth-child(odd) {
        margin-right: 30px;
    }

    .packages-item_top-btn {
        padding: 0 24px;
        width: fit-content;
    }

    .packages-item-desc {
        margin-bottom: 20px;
    }

    .packages-item_top--btn_block {
        width: 100%;
        margin-bottom: 21px;
    }

    .packages-item_top-btn {
        width: 100%;
    }

    .packages-item_top-btn.button.half-wide {
        width: 50%;
    }

    .packages-item-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #282828;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .packages-item-row:last-of-type {
        border-bottom: unset;
    }

    .packages-item-row_priceFull {
        position: relative;
    }

    .packages-item-row_priceFull::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background: #949aa4;
        transform: rotate(168deg) translateY(-50%);
    }

    .packages-item-row_price {
        color: #fff;
        font-weight: 700;
    }

    .packages-item-row_price--white {
        color: #fff;
    }

    .packages-item-row_title {
        width: 50%;
        padding-right: 11px;
        text-align: right;
        line-height: 1.1;
    }

    .packages-item-row_price {
        width: 50%;
        text-align: left;
        padding-left: 11px;
    }

    .packages-item-row_price-block {
        display: flex;
        justify-content: center;
    }

    .packages-item-row_price-block .packages-item-row_price {
        padding-left: 0;
        width: 100%;
        text-align: center;
    }

    .packages-item-row_full-price-block .packages-item-row_price {
        padding-left: 0;
        width: 100%;
        text-align: center;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(1) {
        text-align: right;
        padding-right: 11px;
        margin-right: 0;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(2) {
        text-align: left;
        padding-left: 11px;
        margin-left: 0;
    }

    .packages-item-row_full-price-block {
        padding-top: 0;
    }

    .packages-item-row.packages-item-row_full-price-block .packages-item-row_price {
        font-weight: 400;
        font-size: 14px;
        color: #FFB800;
        width: fit-content;
        margin: 0 auto;
        position: relative;
    }

    .packages-item-row.packages-item-row_full-price-block .packages-item-row_price::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 1px;
        width: 100%;
        background: #FFB800;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(1),
    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(2) {
        position: relative;
        width: inherit;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(1)::after,
    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(2)::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 11px;
        height: 1px;
        width: 30px;
        background: #FFB800;
    }

    .packages-item-row.packages-item-row_full-price-block.two .packages-item-row_price:nth-child(1)::after {
        right: 11px;
        left: unset;
    }

    .packages-item-row.packages-item-row_price-block.two .packages-item-row_price:nth-child(1) {
        text-align: right;
        padding-right: 11px;
    }

    .packages-item-row.packages-item-row_price-block.two .packages-item-row_price:nth-child(2) {
        text-align: left;
        padding-left: 11px;
    }

    .packages-item-row_price-block .packages-item-row_price {
        font-weight: 900;
    }
}

@media screen and (max-width: 992px) {
    .packages-item {
        width: 100%;
    }

    .packages-item:nth-child(odd) {
        margin-right: 0;
    }
}

@media screen and (max-width: 768px) {
    .packages-top_title-block--inner {
        max-width: 300px;
    }

    .packages-top_title::before {
        height: 72px;
    }

    .packages-item-row_title,
    .packages-item-row_price {
        font-size: 14px;
    }
}