.main-wrap {
    position: relative;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: clip;
}

.main-wrap::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 655px;
    height: 540px;
    background-image: url('../../../img/main/design.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -2;
}

.main-wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -140px;
    width: 500px;
    height: 500px;
    background: rgba(217, 36, 153, 0.5);
    filter: blur(300px);
    transform: translate3d(0, 0, 0);
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    padding-top: 50px;
}

.main-title{
    font-weight: 700;
}
.main-subtitle{
    font-size: 40px;
    font-weight: 700;
}

.main-title span {
    margin-right: auto;
}

.main-item {
    width: 50%;
}

.main-desc {
    max-width: 570px;
    line-height: 1.1;
    font-weight: 300;
    font-size: 28px;
    /* margin-bottom: 50px; */
}

.main-wrap button.button.popup_btn--join {
    margin-top: 40px;
}

/* 
.main-wrap button.button.popup_btn--join {
    margin-top: 40px;
    background: #fff;
    color: #070707;
}

.main-wrap button.button.popup_btn--join:hover {
    background: #D92499;
    color: #fff;
}

.main-wrap button.button.popup_btn--join:hover::after {
    background-image: url('../../../img/btn-arr.svg');
}

.main-wrap button.button.popup_btn--join::after {
    background-image: url('../../../img/btn-arr_black.svg');
} */

.main-img {
    transform: scale(1.1) translate(-90px, 62px);
}

.main-content {
    z-index: 1;
}

.main-img_mob {
    display: none;
}

@media screen and (max-width: 1450px) {
    .main-wrap::before {
        width: 515px;
        height: 450px;
    }

    .main {
        padding-top: 50px;
    }

    .main-desc {
        max-width: 510px;
        font-size: 24px;
    }
}

@media screen and (max-width: 1200px) {
    .main-img {
        transform: scale(1.1) translate(-40px, 82px);
    }
}

@media screen and (max-width: 992px) {
    .main {
        flex-direction: column;
    }

    .main-item {
        width: 100%;
    }

    .main-img {
        transform: unset;
    }

    .main-img_pc {
        display: none;
    }

    .main-img_block {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    .main-img_mob {
        order: 1;
        display: block;
    }

    .main-wrap::after {
        bottom: unset;
        top: 0;
    }

    .main-content {
        margin-bottom: 0;
        order: 2;
    }

    .main-wrap {
        min-height: unset;
        height: 100%;
    }
}

@media screen and (max-width: 576px) {
    .main-wrap::after {
        width: 310px;
        height: 310px;
    }

    .main-wrap::before {
        width: 385px;
        height: 410px;
        transform: scale(1.2) translateX(80px);
    }

    .main-desc {
        max-width: 320px;
        font-size: 22px;
    }
}