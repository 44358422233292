.accordion-item {
    padding: 16px;
    margin-bottom: 16px;
    background: #1B1B1B;
    border-radius: 4px;
    color: #fff;
    text-align: left;
}

.accordion-item:last-child {
    margin-bottom: 0;
}

.accordion-desc {
    margin-top: 16px;
    white-space: pre-line;
    opacity: .8;
    line-height: 1.3;
}

.accordion-title {
    width: fit-content;
}

.accordion-item_head svg {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    min-width: 20px;
}

.accordion-item_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}