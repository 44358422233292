.quote-wrap {
    position: relative;
    overflow-x: clip;
    overflow-y: clip;
}

.quote-wrap::after {
    content: '';
    position: absolute;
    right: -15%;
    top: 50%;
    transform: translate(-50%, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #D92499;
    filter: blur(170px);
    z-index: -1;
}

.quote-wrap::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #D92499;
    filter: blur(170px);
    z-index: -1;
}

.quote {
    font-size: 96px;
    text-align: center;
    font-weight: 400;
}

.quote-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.quote-desc{
    text-align: center;
    font-style: italic;
}
.incubator-quote .quote-desc{
    font-size: 26px;
    font-weight: 300;
}

.quote-img {
    display: none;
}

@media screen and (max-width: 1800px) {

    .quote-wrap::after,
    .quote-wrap::before {
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 1450px) {
    .quote {
        font-size: 75px;
    }

    .quote-wrap::after,
    .quote-wrap::before {
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 1200px) {
    .quote {
        font-size: 63px;
    }
}

@media screen and (max-width: 992px) {
    /* .quote span {
        font-size: 135px;
    } */
    /* 
    .quote span:nth-child(2) {
        font-size: 26px;
        max-width: 160px;
        display: inline-block;
        text-align: left;
        width: 100%;
    } */

    .quote {
        font-size: 46px;
    }

    .quote-wrap::after {
        right: -15%;
        top: unset;
        transform: translate(0, 50%) translate3d(0, 0, 0);
        bottom: 0;
        width: 250px;
        height: 250px;
        filter: blur(110px);
    }

    .quote-wrap::before {
        left: 0%;
        top: 0;
        transform: translate(0, -50%) translate3d(0, 0, 0);
        width: 250px;
        height: 250px;
        filter: blur(110px);
    }
}

@media screen and (max-width: 768px) {
    .quote {
        font-size: 34px;
    }
}

@media screen and (max-width: 576px) {
    .incubator-page .quote {
        display: block;
    }

    .quote-img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .quote-wrap::after,
    .quote-wrap::before {
        width: 200px;
        height: 200px;
        filter: blur(100px);
    }
}