.mails-wrap {
    overflow: hidden;
    position: relative;
}

.mails-design {
    position: absolute;
    top: 50%;
    right: -100px;
    transform: translateY(-32%);
    z-index: -1;
}

.mails-wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 50%;
    width: 500px;
    height: 500px;
    background: #1A9317;
    filter: blur(300px);
    z-index: -1;
    transform: translate3d(0, 0, 0) translate(0, 90%);
}

.mails-wrap::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(0, -50%) translate3d(0, 0, 0);
    width: 500px;
    height: 500px;
    background: #1A9317;
    filter: blur(300px);
    z-index: -1;
}

.mails-title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 36px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 10px;
}

.mails-title span {
    color: #1A9317;
}

.mails-title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #1A9317;
    border-radius: 15px;
}

.mails-desc {
    margin-bottom: 30px;
}

.mails-form_label {
    margin-bottom: 5px;
}

.mails-join-beta {
    position: absolute;
    top: 50%;
    right: 17%;
    transform: translateY(-50%) rotate(15deg);
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.mails-checkbox_label {
    line-height: 1.1;
}

@media screen and (max-width: 1600px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 360px;
        height: 360px;
        filter: blur(250px);
    }

    .mails-join-beta {
        width: 250px;
        height: 250px;
        right: 370px;
    }
}

@media screen and (max-width: 1450px) {
    .mails-join-beta {
        right: 100px;
    }
}

@media screen and (max-width: 1200px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 290px;
        height: 290px;
        filter: blur(170px);
    }

    .mails-wrap::before {
        top: 29%;
    }

    .mails-wrap::after {
        bottom: 40%;
    }
}

@media screen and (max-width: 992px) {
    .mails-join-beta {
        width: 150px;
        height: 150px;
        right: unset;
        top: unset;
        bottom: -50px;
        left: 350px;
    }

    .mails-wrap::before,
    .mails-wrap::after {
        width: 260px;
        height: 260px;
        filter: blur(160px);
    }

    .mails-wrap::before {
        transform: translate(0, -80%) translate3d(0, 0, 0);
    }

    .mails-wrap::after {
        transform: translate(0, 120%) translate3d(0, 0, 0);
    }

    .mails-design {
        display: none;
    }
}

@media screen and (max-width: 768px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 210px;
        height: 260px;
        filter: blur(140px);
    }

    .mails-desc {
        max-width: 330px;
    }
}

@media screen and (max-width: 576px) {

    .mails-wrap::before,
    .mails-wrap::after {
        width: 180px;
        height: 180px;
        filter: blur(110px);
    }

    .mails-join-beta {
        width: 100px;
        height: 100px;
        right: unset;
        top: unset;
        bottom: 0px;
        left: 250px;
    }
}