.lab-main_wrap {
    background-image: url('../../../img/labs/bg/pm.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.lab .footer_wrap::before {
    content: unset;
}

.lab-main {
    display: flex;
}

.container.container_padding.lab-main {
    padding-top: 170px;
    padding-bottom: 160px;
}

.lab-main_title {
    font-weight: 600;
}

.lab-main_desc {
    margin-bottom: 50px;
    max-width: 440px;
    line-height: 1.1;
}

.lab-main_content {
    margin-right: 150px;
}

.lab-main_btns .lab-main_btn {
    background: #fff;
    color: #070707;
    margin-right: 43px;
}

.lab-main_btns .lab-main_btn:hover {
    background: #070707;
    color: #fff;
}

.lab-main_btns .lab-main_btn::after {
    background-image: url('../../../img/btn-arr_black.svg');
}

.lab-main_btns .lab-main_btn:hover::after {
    background-image: url('../../../img/btn-arr.svg');
}

.lab-main_btns a span {
    text-decoration: underline;
    margin-right: 5px;
}

.lab-main_btns a {
    display: flex;
    align-items: center;
    width: fit-content;
}

.lab-main_btns a svg {
    width: 12px;
}

.lab-main_btns a:hover {
    color: #070707;
}

.lab-main_btns a:hover path {
    fill: #070707;
}

.lab-main_btns {
    display: flex;
    align-items: center;
}

.lab-main_img-block img {
    max-width: 580px;
    object-fit: contain;
}

@media screen and (max-width: 1450px) {
    .lab-main_img-block img {
        max-width: 520px;
    }

    .lab-main_content {
        margin-right: 80px;
    }

    .container.container_padding.lab-main {
        padding-bottom: 120px;
    }
}

@media screen and (max-width: 1200px) {
    .lab-main_img-block img {
        max-width: 420px;
    }

    .lab-main_desc {
        max-width: 310px;
    }
}

@media screen and (max-width: 992px) {
    .lab-main {
        display: flex;
        flex-direction: column;
    }

    .lab-main_content {
        margin-right: 0;
        order: 2;
        position: relative;
        top: -40px;
        z-index: 1;
    }

    .lab-main_img-block {
        order: 1;
    }

    h1 {
        font-size: 76px;
    }

    .lab-main_desc {
        max-width: 440px;
    }

    .container.container_padding.lab-main {
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 576px) {
    .lab-main_img-block img {
        max-width: unset;
        width: 100%;
    }

    h1 {
        font-size: 56px;
    }
}

@media screen and (max-width: 460px) {
    .lab-main_desc {
        max-width: 310px;
    }
}

@media screen and (max-width: 370px) {
    h1 {
        font-size: 50px;
    }

    .lab-main_btns .lab-main_btn {
        margin-right: 23px;
    }
}