header {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 58px;
    margin: 20px auto 0;
}

.header_container {
    max-width: 1400px;
}

.header_wrap.header_wrap--scroll {
    background: rgba(13, 13, 13, .8);
    backdrop-filter: blur(20px);
    display: block;
    width: 100vw;
    height: fit-content;
    position: fixed;
    top: 0;
    padding: 20px 0;
    z-index: 100;
    transform: translate3d(0, 0, 0);
}

.header_wrap.header_wrap--scroll .header_container {
    position: relative;
    left: unset;
    transform: unset;
    margin: 0 auto;
}

.header_wrap.header_wrap--scroll ul.menu {
    position: relative;
    width: fit-content;
    left: unset;
    transform: unset;
    margin: 0;
}

.header_logo {
    width: 178px;
    height: 50px;
}

.header_logos {
    display: flex;
    align-items: center;
}

.header_logo--geekle {
    width: auto;
    margin-left: 30px;
    height: 17px;
    position: relative;
    top: 22px;
}

.header_logo--geekle img {
    height: 100%;
}

@media screen and (max-width: 1450px) {
    .header_container {
        max-width: 1100px;
    }
}

@media screen and (max-width: 1200px) {
    .header_container {
        max-width: 920px;
    }

    .header_wrap.header_wrap--scroll ul.menu {
        width: 100%;
    }

    nav {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .header_container {
        max-width: 680px;
        padding: 0 10px;
    }
}

@media screen and (max-width: 768px) {
    .header_container {
        max-width: 500px;
    }

    .header_wrap.header_wrap--scroll {
        padding: 5px 0;
    }
}

@media screen and (max-width: 640px) {
    header {
        margin: 10px auto 0;
    }
}

@media screen and (max-width: 576px) {
    .header_container {
        max-width: 100%;
    }
}