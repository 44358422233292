.footer_wrap {
    position: relative;
    padding: 1px 0;
    flex: 0 0 auto;
    overflow-x: clip;
}

.footer_wrap::before {
    content: '';
    position: absolute;
    right: -15%;
    top: 20%;
    width: 470px;
    height: 470px;
    background: #D92499;
    filter: blur(350px);
    z-index: -1;
    transform: translate3d(0, 0, 0) translate(-50%, -50%);
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;
}

.footer_geekle_logo {
    max-width: 232px;
    height: auto;
    width: 100%;
    margin: 0 auto;
}

.footer_links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_link_block {
    display: flex;
    margin-top: auto;
}

.footer_link_img {
    width: 26px;
    height: 26px;
    object-fit: contain;
}

.footer_link {
    text-decoration: none;
    margin-right: 46px;
}

.footer,
.footer_contact {
    color: #fff;
}

.footer_link_geekle {
    text-decoration: none;
    max-width: 252px;
    width: 100%;
}

.footer_link:last-child {
    margin-right: 0;
}

.footer_address {
    margin-top: auto;
}

.footer_contacts {
    display: flex;
    flex-direction: column;
}

.footer_docs {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.footer_contacts_wrap {
    align-items: center;
    display: flex;
}

.footer_contacts_wrap {
    margin-bottom: 12px;
}

.footer_contacts_wrap:last-child {
    margin-bottom: 0;
}

.footer_contacts_wrap svg {
    width: 18px;
    margin-right: 19px;
}

.footer_docs_item {
    font-weight: 400;
    display: flex;
    align-items: center;
}

.footer_address {
    text-align: center;
    margin-bottom: 20px;
}

.footer_address--mob {
    display: none;
}

.footer_docs_item span {
    color: #D92499;
    text-decoration: underline;
}

.footer_docs_item svg {
    width: 13px;
    margin-left: 5px;
}

.footer_docs_item:first-of-type {
    margin-bottom: 20px;
}

.chat_block {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.chat_btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
}

.chat_title {
    font-size: 38px;
}

.chat_undertitle {
    margin-bottom: 20px;
}

.chat_btn svg {
    width: 21px;
    margin-right: 12px;
}

.chat_btn path {
    transition: .4s;
}

.chat_btn:hover path {
    fill: #0B0B0B;
}

.footer_docs_item:hover span {
    color: #fff;
}

.footer_docs_item:hover path {
    fill: #fff;
}

.footer_link_geekle.footer_link_geekle--small {
    max-height: 70px;
    width: auto;
    margin-bottom: 28px;
}

.footer_link_geekle {
    margin-bottom: 13px;
}

.footer_link_geekle--small img {
    height: 100%;
    width: 88px;
}

@media screen and (max-width: 1600px) {
    .footer_wrap::before {
        width: 370px;
        height: 370px;
        filter: blur(300px);
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .footer_item {
        width: 100%;
        align-items: center;
    }

    .footer_address {
        margin-bottom: 0px;
    }

    .footer_contacts {
        margin-bottom: 50px;
    }

    .chat_block {
        order: 1;
        padding-bottom: 80px;
        margin-bottom: 50px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        width: 100%;
    }

    .footer_links {
        order: 2;
        margin-bottom: 40px;
    }

    .footer_link {
        margin-right: 46px;
    }

    .footer_info {
        order: 3;
        text-align: center;
    }

    .footer_docs_item:first-of-type::after {
        left: 50%;
        transform: translateX(-50%);
    }

    .footer_contacts_wrap {
        justify-content: center;
    }

    .footer_address {
        padding: 0;
    }

    .footer_address--pc {
        display: none;
    }

    .footer_address--mob {
        display: block;
    }

    .chat_title {
        font-size: 24px;
    }
}

@media screen and (max-width: 576px) {
    .chat_block {
        text-align: left;
    }

    .chat_btn {
        margin-left: 0;
    }

    .footer_wrap::before {
        width: 165px;
        height: 165px;
        filter: blur(150px);
    }

    .footer_item {
        align-items: flex-start;
        width: 100%;
    }

    .footer_info {
        width: 100%;
    }

    .footer_address {
        text-align: left;
    }

    .footer_link_geekle {
        margin-bottom: 24px;
    }

    .footer_link_geekle.footer_link_geekle--small {
        max-height: 88px;
        height: 88px;
    }

    .footer_geekle_logo {
        object-fit: contain;
    }
}