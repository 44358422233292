/* new calendar items */
.calendar-items{
    display: flex;
    flex-direction: column;
}
.calendar-item{
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    border-top: 1px solid #282828;
    padding: 18px 25px 9px;
    font-size: 26px;
}
.calendar-item:last-child{
    border-bottom: 1px solid #282828;
}
.calendar-item_times{
    display: grid;
    grid-template-columns: 150px 150px;
}
.calendar-item_times__date{
    color: #f6f6f6;
}
.calendar-item_times__date span{
    color: #ababab;
}
.calendar-item_times__time{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    height: fit-content;
    color: #ababab;
}
.calendar-item_times__time::before{
    content: '';
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #04690E;
}
.calendar-item_information{
    display: grid;
    /* grid-template-columns: 90px 1fr; */
    grid-template-columns: 1fr 90px;
    grid-gap: 20px;
}

/* END new calendar items */

.calendar-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.calendar-tabs {
    display: flex;
}

.calendar-tab {
    margin-right: 15px;
    cursor: pointer;
}

/* .calendar-tab:hover {
    color: #D92499;
} */

.calendar-tab.active {
    position: relative;
}

.calendar-tab.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    height: 4px;
    border-radius: 15px;
    background: #D92499;
}

.calendar-tab:last-of-type {
    margin-right: 0;
}

.calendar-title {
    margin-bottom: 10px;
}

.calendar-desc {
    margin-bottom: 30px;
    line-height: 1.15;
}

.calendar-dropdown {
    position: relative;
}

.calendar-header {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.calendar-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #070707;
    border: 1px solid #fff;
    border-radius: 5px;
    z-index: 1;
}

.calendar-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.calendar-content li {
    padding: 10px;
    cursor: pointer;
}

.calendar-content li:hover {
    color: #D92499;
}

.calendar-arrow {
    width: 19px;
    height: 1px;
    position: relative;
    margin-left: 5px;
}

.calendar-arrow::before,
.calendar-arrow::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 1px;
    background-color: #fff;
}

.calendar-arrow::before {
    top: 0;
    left: 0;
    transform: rotate(45deg);
}

.calendar-arrow::after {
    bottom: 0;
    right: 0;
    transform: rotate(-45deg);
}

.open .calendar-arrow {
    transform: translateY(-50%) rotate(-180deg);
}

/*Month*/

.calendar-month_title {
    line-height: 1;
    text-align: center;
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.calendar-month:first-of-type .calendar-month_title {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

/*Day*/

.calendar-day {
    display: flex;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.calendar-day_head {
    width: 150px;
    padding-right: 15px;
}

.calendar-day_pic {
    width: 124px;
    height: auto;
    object-fit: contain;
    margin-right: 30px;
    border-radius: 5px;
}

.calendar-day_content-desc {
    line-height: 1.1;
}

.calendar-btn {
    margin-top: 50px;
}

.calendar-day:hover {
    background: rgba(217, 36, 153, 0.1);
}

.calendar-img--mob {
    display: none;
}

.calendar-img {
    width: 100%;
    object-fit: contain;
    height: auto;
}

@media screen and (max-width: 992px) {
    .calendar-day_head {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    /* new calendar items */
    .calendar-item{
        padding: 20px 0px 18px;
        grid-gap: 0px;
        line-height: 1.3em;
    }
    .calendar-item_times{
        grid-template-columns: 150px;
    }
    .calendar-item_times__time::before{
        display: none;
    }
    .calendar-item_information{
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    /* END new calendar items */

    .calendar-day_pic {
        width: 53px;
        margin-right: 10px;
    }

    .calendar-day {
        align-items: flex-start;
    }

    .calendar-day_content {
        display: flex;
        flex-direction: column;
    }

    .calendar-day_content-title {
        order: 2;
    }

    .calendar-day_content-desc {
        order: 1;
        margin-bottom: 6px;
    }

    .calendar-img--mob {
        display: block;
    }

    .calendar-img--pc {
        display: none;
    }
}
@media screen and (max-width: 450px) {
    /* new calendar items */
    .calendar-item{
        font-size: 18px;
        line-height: 1.2em;
    }
    .calendar-item_times{
        grid-template-columns: 120px;
    }
    .calendar-item_information{
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    /* END new calendar items */
}