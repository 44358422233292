.packages-head {
    text-align: center;
    padding: 150px 0 80px;
    margin-top: 0;
    margin-bottom: 0;
}

.packages-head_wrap {
    position: relative;
    overflow-y: clip;
}

.packages-head_wrap::after {
    content: '';
    position: absolute;
    left: 5%;
    top: 0%;
    width: 470px;
    height: 470px;
    background: #D92499;
    filter: blur(300px);
    z-index: -1;
    transform: translate3d(0, 0, 0) translate(-60%, -80%);
}

.packages-head_wrap::before {
    content: '';
    position: absolute;
    right: -15%;
    bottom: -20%;
    width: 470px;
    height: 470px;
    background: #D92499;
    filter: blur(300px);
    z-index: -1;
    transform: translate3d(0, 0, 0) translate(-50%, 60%);
}

.packages-head_title {
    font-size: 65px;
    margin-bottom: 25px;
    line-height: 1.1;
}

.packages-head_title span {
    color: #D92499;
}

.packages-head_desc {
    font-weight: 700;
    font-size: 46px;
    text-transform: uppercase;
}

.packages-head_img {
    display: none;
}

.packages-head_design {
    display: none;
}

@media screen and (max-width: 1600px) {
    .packages-head_wrap::before {
        width: 370px;
        height: 370px;
        filter: blur(200px);
        right: -17%;
    }

    .packages-head_wrap::after {
        width: 370px;
        height: 370px;
        filter: blur(200px);
        transform: translate3d(0, 0, 0) translate(-50%, -70%);
    }
}

@media screen and (max-width: 992px) {
    .packages-head_wrap::before {
        width: 270px;
        height: 270px;
        filter: blur(150px);
        right: -27%;
    }

    .packages-head_wrap::after {
        width: 270px;
        height: 270px;
        filter: blur(150px);
    }
}

@media screen and (max-width: 768px) {
    .packages-head_title {
        display: none;
    }

    .packages-head_img {
        display: block;
        width: 100%;
        height: auto;
    }

    .packages-head {
        padding: 180px 10px 80px;
    }

    .packages-head_desc {
        font-size: 26px;
    }

    .packages-head_design {
        display: block;
        position: absolute;
        object-fit: contain;
        top: 0;
        right: -200px;
        width: 300px;
        height: 300px;
        transform: scale(2);
        z-index: -1;
    }
}