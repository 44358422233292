.openMenu {
    position: fixed;
    width: 150%;
    background-color: #202123;
    height: fit-content;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s;
    color: #fff;
    padding: 100px 20px 60px;
    border-bottom: 1px solid #D92499;
    overflow: hidden;
    z-index: 2;
}

.openMenu::before {
    content: ' ';
    /* background: #830081; */
    opacity: 0.25;
    filter: blur(150px);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 50%;
    transform: translate(-50%, -50%) translate3d(0, 0, 0);
}

.openMenu ul {
    margin-left: 0;
}

.openMenu ul li {
    list-style-type: none;
    text-align: center;
    font-size: 20px;
    margin: 0;
}

.openMenu ul li a {
    font-weight: bold;
    font-size: 18px;
    transition: .4s;
    color: #fff;
}

.openMenu_close {
    width: 59px;
    height: 45px;
    margin-left: auto;
    position: absolute;
    right: 39px;
    top: 29px;
}

.openMenu li a {
    height: 38px !important;
}

.openMenu li {
    margin-top: 10px !important;
}

.openMenu_logo {
    margin-right: auto;
    position: absolute;
    left: 40px;
    top: 21.5px;
    max-width: 70px;
}