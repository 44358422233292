.packages-wrap_main .packages {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    justify-content: space-between;
}

.packages-wrap_main .packages-card {
    background: #1B1B1B;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.packages-wrap_main .packages-card:nth-child(1) {
    margin-right: 40px;
    max-width: 360px;
    width: 100%;
}

.packages-wrap_main .packages-card-wrap {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.packages-wrap_main .packages-card-wrap img {
    height: 234px;
    max-width: 380px;
    width: 100%;
    object-fit: cover;
}

.packages-wrap_main .packages-card-wrap .packages-card {
    border-radius: 0;
}

.packages-wrap_main .packages-btn {
    margin-top: auto;
}

.packages-wrap_main .packages-card .popup_btn--join {
    margin-top: auto;
}

.packages-wrap_main .packages-title {
    margin-bottom: 10px;
}

.packages-wrap_main .packages-desc {
    line-height: 1.1;
    margin-bottom: 20px;
    max-width: 300px;
}

.packages-wrap_main .packages-desc span {
    font-weight: 700;
    color: #fff;
}

@media screen and (max-width: 1450px) {
    .packages-wrap_main .packages-card-wrap img {
        height: 251px;
        max-width: 260px;
    }
}

@media screen and (max-width: 1200px) {
    .packages-wrap_main .packages-card-wrap img {
        height: 100%;
        max-width: unset;
        width: auto;
    }

    .packages-wrap_main .packages-card:nth-child(1) {
        max-width: unset;
        width: auto;
    }

    .packages-wrap_main .packages-card-wrap {
        width: auto;
    }
}

@media screen and (max-width: 992px) {
    .packages-wrap_main .packages {
        flex-direction: column;
    }

    .packages-wrap_main .packages-card:nth-child(1) {
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .packages-wrap_main .packages-card {
        background: transparent;
        padding: 0;
    }

    .packages-wrap_main .packages-card-wrap {
        flex-direction: column;
    }

    .packages-wrap_main .packages-card {
        background: #1B1B1B;
        border-radius: 5px;
        padding: 40px 12px 40px;
    }

    .packages-wrap_main .packages-card-wrap img {
        height: 170px;
        position: relative;
    }

    .packages-wrap_main .packages-card-wrap .packages-btn {
        margin-left: auto;
        margin-right: auto;
    }

    .packages-wrap_main .packages-card-wrap .packages-title {
        /* margin-top: 15px; */
        margin-bottom: 15px;
    }
}