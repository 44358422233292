.enjoy {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.enjoy-content {
    max-width: 600px;
}

.enjoy-title {
    margin-bottom: 10px;
}

.enjoy-desc {
    margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
    .enjoy {
        flex-direction: column;
    }

    .enjoy-head .popup_btn--join {
        display: none;
    }

    .enjoy-content {
        max-width: unset;
    }

    .enjoy-gif {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .enjoy-gif {
        transform: scale(1.1);
    }
}