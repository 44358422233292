.practices {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.container.container_padding.practices {
    padding-top: 15px;
}

.practices-wrap {
    position: relative;
}

.practices-wrap::after {
    content: '';
    position: absolute;
    right: -15%;
    top: 50%;
    transform: translate(-50%, -50%) translate3d(0, 0, 0);
    width: 470px;
    height: 470px;
    background: #FFB800;
    filter: blur(350px);
    z-index: -1;
}

.practices-card_content {
    padding: 15px;
}

.practices-title {
    margin-bottom: 10px;
}

.practices-desc {
    margin-bottom: 30px;
    line-height: 1.1;
}

.practices-content {
    display: flex;
    flex-wrap: wrap;
}

.practices-card {
    width: calc((100% / 4) - 15px);
    margin-right: 20px;
    margin-bottom: 20px;
    background: #141414;
    border-radius: 5px;
    overflow: hidden;
}

.practices-card:last-of-type {
    margin-right: 0;
}

.practices-card_btn:hover span {
    color: #fff;
}

.practices-card_btn:hover path {
    fill: #fff;
}

.practices-card_img {
    width: 100%;
    object-fit: cover;
}

.practices-card_btn {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.practices-card_btn span {
    width: fit-content;
    text-decoration: underline;
    color: #D92499;
}

.practices-card_btn svg {
    width: 13px !important;
    margin-left: 8px;
}

.practices-card .popup_btn--join {
    margin-top: 18px;
    line-height: 1;
    padding: 0;
    background: transparent;
    height: unset;
    color: #FFB800;
    text-decoration: underline;
}

.practices-card .popup_btn--join:hover {
    color: #fff;
}

.practices-card {
    transition: .3s;
}

.practices-card:hover {
    transform: translateY(-10px);
}

.practices-card .popup_btn--join::after {
    background-image: url('../../../img/btn-arr_yellow.svg');
    right: -17px;
}

.practices-card .popup_btn--join:hover::after {
    background-image: url('../../../img/btn-arr.svg');
}

.practices-motivation_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.practices-motivation_img {
    width: 191px;
    margin-right: 100px;
    transform: rotate(17deg) translateY(20px);
}

.practices-motivation_title {
    font-weight: 700;
    font-size: 36px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 10px;
}

.practices-motivation_title span {
    color: #FFB800;
}

.practices-motivation_title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #FFB800;
    border-radius: 15px;
}

.practices-motivation_desc {
    line-height: 1.1;
}

.practices-btn_wrap .popup_btn--join {
    background: #FFB800;
    margin-top: 20px;
    color: #070707;
}

.practices-btn_wrap .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.practices-btn_wrap .popup_btn--join:hover::after {
    background-image: url('../../../img/btn-arr_black.svg');
}

.practices-btn_wrap .popup_btn--join::after {
    background-image: url('../../../img/btn-arr_black.svg');
}

@media screen and (max-width: 1450px) {
    .practices-motivation_img {
        margin-right: 60px;
    }

    .practices-wrap::after {
        width: 370px;
        height: 370px;
        filter: blur(300px);
    }
}

@media screen and (max-width: 1200px) {
    .practices-card {
        width: calc((100% / 3) - 20px);
    }

    .practices-card:nth-child(3n) {
        margin-right: 0;
    }

    .practices-wrap::after {
        width: 270px;
        height: 270px;
        filter: blur(200px);
    }
}

@media screen and (max-width: 992px) {
    .practices-motivation_img {
        position: absolute;
        right: 50px;
        top: 70px;
        width: 141px;
        height: 141px;
        margin-right: 0;
    }

    .container.container_padding.practices {
        padding-top: 80px;
    }

    .practices-motivation_block {
        margin-bottom: 70px;
    }

    .practices-card {
        width: calc((100% / 2) - 10px);
    }

    .practices-card:nth-child(3n) {
        margin-right: 20px;
    }

    .practices-card:nth-child(2n) {
        margin-right: 0px;
    }

    .practices-desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 768px) {
    .practices-motivation_img {
        top: 40px;
    }

    .practices-motivation_desc {
        max-width: 300px;
    }
}

@media screen and (max-width: 576px) {
    .practices-card {
        width: 100%;
        margin-right: 0;
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
    }

    .practices-card:nth-child(3n) {
        margin-right: 0px;
    }

    .practices-motivation_title {
        font-size: 26px;
    }

    .practices-motivation_img {
        right: unset;
        left: 170px;
        top: 67px;
        width: 100px;
        height: 100px;
    }

    .practices-wrap::after {
        width: 130px;
        height: 130px;
        filter: blur(90px);
        top: 0%;
        right: -28%;
    }
}