.userflow-wrapper {
    position: relative;
    overflow-y: clip;
    display: grid;
    min-height: 100dvh;
}

.userflow-wrapper::after,
.userflow-wrapper::before {
    content: '';
    position: absolute;
    width: 100vw;
    min-width: 300px;
    height: 22vh;
    min-height: 200px;
    background: rgba(217, 36, 153, 0.4);
    filter: blur(200px);
    transform: translate3d(0, 0, 0);
    z-index: -1;
}

.userflow-wrapper::after {
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%) translate3d(0, 0, 0);
}

.userflow-wrapper::before {
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 50%) translate3d(0, 0, 0);
}

.userflow-design {
    position: absolute;
    top: -140px;
    right: 0;
    width: 480px;
    height: 480px;
    transform: scale(2) rotate(-25deg);
}

.userflow-wrap {
    display: none;
    /* padding-top: 50px; */
    align-self: stretch;
}

.userflow-wrap.active {
    display: grid;
}

.userflow {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.userflow-img {
    max-width: 555px;
    object-fit: contain;
    margin-bottom: 70px;
}

.userflow-title {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 10px;
}

.userflow-desc {
    color: #CBCBCB;
    max-width: 625px;
    line-height: 1.1;
    margin-bottom: 36px;
}

.userflow-arr {
    width: 9px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.userflow-arr:hover path {
    stroke: #fff;
}

.userflow-arr_prev {
    left: 0;
}

.userflow-arr_next {
    right: 0;
}

.userflow-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    width: 43%;
}

.userflow-dot {
    width: 9px;
    min-width: 9px;
    height: 9px;
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    margin-right: 14px;
}

.userflow-dot.active {
    background: #fff;
}

.userflow-dot:last-of-type {
    margin-right: 0;
}

.button.userflow-btn.grey-btn {
    background: transparent;
    padding: 0;
    color: #7B7B7B;
}

.button.userflow-btn.grey-btn::after {
    background-image: url('../../img/btn-arr_grey.svg');
    right: -17px;
}

.button.userflow-btn.grey-btn:hover {
    color: #fff;
}

.button.userflow-btn.grey-btn:hover::after {
    background-image: url('../../img/btn-arr.svg');
}

.userflow-links {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.userflow-link {
    margin-right: 46px;
}

.userflow-link:last-of-type {
    margin-right: 0;
}

@media screen and (max-width: 1600px) {
    .userflow-img {
        max-width: 455px;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 992px) {
    .userflow-desc {
        margin-bottom: auto;
    }

    .userflow-dots {
        margin-top: 20px;
    }

    .userflow-title {
        margin-top: 40px;
    }

    .userflow-img {
        margin-bottom: auto;
    }

    .userflow-wrap {
        max-height: 820px;
    }
}

@media screen and (max-width: 768px) {
    .userflow-img {
        max-width: 400px;
        width: 100%;
    }

    .userflow-dots {
        width: 93%;
    }

    .userflow-wrap {
        padding-top: 50px;
    }

    .userflow-design {
        display: none;
    }

    .userflow-arr {
        width: 20px;
        height: 20px;
    }

    .userflow-wrapper::after,
    .userflow-wrapper::before {
        filter: blur(110px);
    }

    .userflow-title {
        font-size: 26px;
    }

    .userflow-desc {
        margin-bottom: 50px;
    }

    .userflow-dots {
        margin-bottom: 30px;
    }
}