@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  word-break: break-word;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
}

body {
  position: relative;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  background-color: #070707;
  color: #fff;
  font-family: 'Heebo', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

main {
  flex: 1 0 auto;
  overflow-x: clip;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

img {
  max-width: 100%;
  border-style: none;
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

svg {
  display: block;
  width: 100%;
}

ul li,
ol li {
  list-style-type: none;
}

label {
  cursor: pointer;
}

button {
  cursor: pointer;
}

iframe {
  border: none;
  outline: none;
}

.main-color {
  color: #008294;
}

.subcolor-1 {
  color: #CBCBCB;
}

.white {
  color: #fff;
}

input,
textarea {
  background: none;
  outline: none;
  border: 0;
  margin: 0;
  padding: 12px 16px;
  color: #fff;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: .625px;
  text-overflow: ellipsis;
  resize: none;
  box-shadow: inset 0 0 0 1px #3F3F3F;
  background: transparent;
  border-radius: 4px;
  transition: color .2s ease, box-shadow .2s ease;
}

input.invalid,
textarea.invalid {
  border-color: #cd1010;
}

input:focus,
textarea:focus {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: #CBCBCB;
}

.text-colorful {
  color: #D92499;
}