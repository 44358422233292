ul.menu {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
}

ul.menu li {
    text-align: center;
    margin-left: 25px;
}

.menu_pc {
    transition: .4s;
}

ul.menu li a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 18px;
    color: #CBCBCB;
    text-decoration: none;
    width: fit-content;
    position: relative;
}

ul.menu li a::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: #D92499;
    border-radius: 20px;
    width: 100%;
    height: 3px;
    opacity: 0;
    transition: .3s ease-in;
}

ul.menu li a:hover {
    color: #fff;
}

/* .header_container .popup_btn--join {
    padding: 0;
    background: transparent;
    height: unset;
    color: #CBCBCB;
    margin-left: 25px;
    font-weight: 400;
}

.header_container .popup_btn--join:hover {
    color: #fff;
}

.header_container .popup_btn--join:hover:before {
    opacity: 1;
}

.header_container .popup_btn--join::after {
    content: none;
}

.header_container .popup_btn--join::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: #D92499;
    border-radius: 20px;
    width: 100%;
    height: 3px;
    opacity: 0;
    transition: .3s ease-in;
} */
.header_container .popup_btn--join {
    width: 161px;
    padding: 0 40px 0 20px;
    margin-left: 25px;
}

.header_container .popup_btn--join::after {
    right: 28px;
}

ul.menu li a:hover {
    box-shadow: none;
}

ul.menu li a:hover::after {
    opacity: 1;
}

.menu_pc:hover a {
    color: #fff;
}

.mobile-menu {
    display: none;
    justify-content: space-between;
    align-items: baseline;
}

.mob_blur_bcg {
    position: absolute;
    top: -30px;
    right: -200px;
    width: 1320px;
    height: 110vh;
    display: block;
    background: #343541;
    filter: blur(10px);
    opacity: .4;
    z-index: -1;
    transform: translate3d(0, 0, 0);
}

@media screen and (max-width: 1200px) {
    .menu_pc {
        display: none;
    }

    .header_container .popup_btn--join {
        display: none;
    }

    .openMenu .popup_btn--join {
        display: flex;
        margin-left: 0;
        margin-top: 16px;
    }

    .openMenu .popup_btn--join::before {
        content: none;
    }

    ul.menu li {
        margin-left: 0;
    }

    .mobile-menu {
        display: flex;
        /* padding: 10px 20px;  */
        margin-left: auto;
        width: 100%;
    }

    .mobile-menu_img-block {
        width: 38px;
        height: 38px;
        display: flex;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        z-index: 5;
    }

    ul.menu {
        width: 100%;
        left: 50%;
        top: 0px;
        padding-top: 26px;
    }

    .header_wrap--scroll ul.menu {
        padding-top: 0
    }

    ul.menu li a {
        margin: 0 auto;
        padding-bottom: 30px;
        padding-top: 30px;
        color: #fff;
    }

    .header_container .popup_btn--join {
        color: #fff;
    }

    .header_logo {
        display: none;
    }

    .logo-mob {
        max-width: 170px;
        width: 100%;
        z-index: 5;
    }

    .logo-mob__geekle {
        width: auto;
        height: 17px;
        margin-left: 35px;
    }

    .logo-mob__geekle img {
        height: 100%;
    }

    .header_wrap--scroll .header_container {
        padding-top: 0
    }

    .header_container {
        padding-top: 26px;
        margin-top: 0;
    }

    ul.menu li a::before {
        display: none;
    }

    ul.menu li a::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        left: 50%;
        background: #830081;
        width: 25px;
        height: 2px;
    }

    .menu_pc--after a::after {
        display: none;
    }

    ul.menu li.menu_pc--without_before a {
        padding-top: 0;
    }
}

@media screen and (max-width: 768px) {
    .logo-mob {
        max-width: 150px;
    }

    .logo-mob__geekle {
        height: 17px;
        margin-left: 24px;
        top: 8px;
        position: relative;
    }
}