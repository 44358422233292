.lab-discord_wrap {
    overflow: hidden;
}

.lab-discord_creative {
    position: absolute;
    right: 25vw;
    top: 0;
    transform: translateY(-40%);
    object-fit: contain;
    width: 628px;
    height: auto;
}

.lab-discord_wrap::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 50%;
    width: 400px;
    height: 400px;
    background: #FFB800;
    filter: blur(300px);
    z-index: -1;
    transform: translate3d(0, 0, 0) translate(0, 90%);
}

.lab-discord_wrap::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(0, -50%) translate3d(0, 0, 0);
    width: 400px;
    height: 400px;
    background: #FFB800;
    filter: blur(300px);
    z-index: -1;
}

.lab-discord_title {
    font-weight: 700;
    font-size: 36px !important;
    max-width: 570px;
}

.lab-discord_title span {
    background: -webkit-linear-gradient(176deg, #FFC130 0%, #EF6F96 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container-labs.container_padding.lab-discord {
    padding: 40px 0;
}

.lab-discord_design {
    position: absolute;
    top: 50%;
    right: -100px;
    transform: translateY(-39%) rotate(6deg);
    z-index: -1;
    max-height: 1080px;
}

@media screen and (max-width: 2100px) {
    .lab-discord_creative {
        right: 16vw;
    }
}

@media screen and (max-width: 1600px) {

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 360px;
        height: 360px;
        filter: blur(250px);
    }

    .lab-discord_creative {
        width: 548px;
    }
}

@media screen and (max-width: 1450px) {
    .lab-discord_creative {
        right: 60px;
    }

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 290px;
        height: 290px;
        filter: blur(170px);
    }
}

@media screen and (max-width: 1200px) {
    .lab-discord_title {
        font-size: 34px !important;
        max-width: 470px;
    }

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 260px;
        height: 260px;
        filter: blur(160px);
    }

    .lab-discord_creative {
        right: 20px;
        width: 458px;
    }

    .lab-discord_design {
        right: -290px;
    }
}

@media screen and (max-width: 992px) {
    .container-labs.container_padding.lab-discord {
        padding-top: 120px;
    }

    .lab-discord_creative {
        right: unset;
        left: 50%;
        transform: translateX(-50%);
        top: -100px;
    }

    .lab-discord_title {
        max-width: 540px;
    }

    .lab-discord_design {
        transform: translateY(-29%) rotate(6deg);
    }

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 210px;
        height: 260px;
        filter: blur(140px);
    }
}

@media screen and (max-width: 768px) {

    .lab-discord_wrap::before,
    .lab-discord_wrap::after {
        width: 180px;
        height: 180px;
        filter: blur(110px);
    }

    .lab-discord_wrap::before {
        top: 20%;
    }

    .lab-discord_wrap::after {
        bottom: 20%;
    }

    .lab-discord_creative {
        width: 378px;
        top: -80px;
    }
}

@media screen and (max-width: 576px) {
    .lab-discord_creative {
        width: 340px;
    }

    .container-labs.container_padding.lab-discord {
        padding-top: 100px;
    }

    .lab-discord_title {
        max-width: 343px;
    }
}