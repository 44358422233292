.popup-overlay {
    background: rgba(23, 22, 22, .5);
    backdrop-filter: blur(2.5px);
}

.modal {
    width: 547px;
    height: auto;
    max-height: calc(100vh - 200px);
    padding: 40px;
    background-image: url('../../../img/popup-bg.png');
    background-size: cover;
    background-position: center;
    overflow-y: scroll;
    padding: 40px;
}

.popup-content .close {
    display: block;
    position: absolute;
    right: 8px;
    top: 12px;
    z-index: 10;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.packages-content_body {
    padding: 45px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packages-btn {
    border: none;
    box-shadow: none;
}

.packages-form {
    width: 100%;
    margin-bottom: 30px;
}

.packages-form_block {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.packages-form_input {
    margin-top: 25px;
    color: #fff;
}

.packages-form_input:focus {
    border-color: #fff;
}

.packages-form_input:nth-child(1) {
    margin-top: 0;
}

.packages-checkbox_label {
    line-height: 1.1;
    max-width: 308px;
}

.packages-checkbox_label a {
    color: #D92499;
    line-height: 1.1;
}

.packages-checkbox_label a:hover {
    color: #fff;
}

.form-error {
    color: #ff0000;
    margin-top: 7px;
    line-height: 1;
}

.packages-btn_block {
    width: fit-content;
}

.packages-btn_block::after {
    content: '';
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url('../../../img/btn-arr.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.packages-btn_block:hover::after {
    background-image: url('../../../img/btn-arr_black.svg');
}

.button.packages-btn.packages-btn_unactive {
    opacity: .3;
}

.button.packages-btn.packages-btn_unactive:hover {
    opacity: .3;
}

.packages-form_input.error {
    border-color: #ff0000;
}

.packages-sales {
    font-weight: 700;
    margin-bottom: 10px;
}

.modal.modal--join{
    opacity: 1;
    transition: all ease-in-out .3s;
}
.modal.modal--join .packages-title{
    margin-bottom: 30px;
}
.modal.modal--join.success-modal{
    position: absolute;
    top: 0;
    z-index: -1;
    transition: all ease-in-out .3s;
}

.popup-content.success .modal.modal--join{
    opacity: 0;
}
.popup-content.success .modal.modal--join.success-modal{
    opacity: 1;
}

@media screen and (max-height: 900px) {
    .modal {
        max-height: calc(100vh - 70px);
    }
}

@media screen and (max-width: 1450px) {
    .packages-form {
        max-width: unset;
    }

    .packages-content_body {
        padding: 45px 35px;
    }
}

@media screen and (max-width: 1200px) {
    .modal {
        width: calc(100vw - 100px);
    }
}

@media screen and (max-width: 768px) {
    .packages-content_body {
        padding: 25px;
    }

    .modal {
        width: 100vw;
        max-height: calc(100vh - 70px);
        padding: 30px 20px;
    }
}