.sf {
    display: flex;
}

.sf-wrap {
    background: #000;
}

.sf-head {
    margin-right: 50px;
    max-width: 203px;
    width: 100%;
}

.sf-content {
    display: flex;
    flex-wrap: wrap;
}

.sf-logo:nth-child(4n) {
    padding-right: 0;
}

.sf-logo:nth-child(4n + 1) {
    padding-left: 0;
}

.sf-logo {
    width: calc(100% / 4);
    padding: 5px 42px;
    object-fit: contain;
    object-position: center;
}

.sf-content_mob {
    display: none;
}

.scrolling-images-container {
    display: none;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.scrolling-images-container::-webkit-scrollbar {
    width: 4px;
}

.scrolling-images-container::-webkit-scrollbar-track {
    background: transparent;
}

.scrolling-images-container::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
}

.scrolling-image-wrapper {
    flex: 0 0 115px;
    margin-right: 10px;
    margin-left: 10px;
}

.scrolling-image-wrapper img {
    width: 100%;
    height: auto;
    max-height: 53px;
}

@media screen and (max-width: 1450px) {
    .sf-logo {
        padding: 5px 20px;
    }
}

@media screen and (max-width: 1200px) {
    .sf-head {
        margin-right: 0;
        max-width: unset;
        width: 100%;
        margin-bottom: 30px;
    }

    .sf {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .sf-content {
        display: none;
    }

    .scrolling-images-container {
        display: flex;
        align-items: center;
    }

    .container.container_padding.sf {
        margin-left: 0;
        margin-right: 0;
        max-width: 100% !important;
    }

    .sf-head {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .sf-head {
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px);
    }
}