.inspiration {
    display: flex;
    justify-content: space-between;
    /* border-top: 1px solid rgba(255, 255, 255, 0.15); */
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
}

.inspiration-gif {
    max-width: 600px;
    width: 100%;
    height: auto;
    object-fit: contain;
}

.inspiration-title {
    font-weight: 700;
    font-size: 46px;
    line-height: 123.5%;
}

.inspiration-desc {
    line-height: 1.1;
    margin-top: 10px;
}

.inspiration-head-wrap {
    max-width: 420px;
}

.inspiration-head {
    height: fit-content;
    padding-left: 13px;
    position: relative;
}

.inspiration-head::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #D92499;
    border-radius: 15px;
}

@media screen and (max-width: 1450px) {
    .inspiration-title {
        font-size: 36px;
    }

    .inspiration-head {
        max-width: 280px;
    }
}

@media screen and (max-width: 1200px) {
    .inspiration-head-wrap {
        margin-right: 40px;
        max-width: 350px;
    }
}

@media screen and (max-width: 992px) {
    .inspiration {
        flex-direction: column;
    }

    .inspiration-head-wrap {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    .inspiration {
        overflow-x: hidden;
        margin-left: 0;
        margin-right: 0;
        padding-right: 10px;
        padding-left: 10px;
        max-width: 100% !important;
    }

    .inspiration-gif {
        max-width: unset;
    }

    .inspiration-head-wrap {
        max-width: 500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 576px) {
    .inspiration-gif {
        transform: scale(1.1);
    }

    .inspiration-head-wrap {
        max-width: unset;
        margin-left: 10px;
        margin-right: 10px;
    }

    .inspiration-head-wrap {
        margin-left: 0;
        margin-right: 0;
    }

    .inspiration-head-wrap {
        margin-bottom: 40px;
        max-width: 370px;
    }

    .inspiration-title {
        font-size: 26px;
    }
}