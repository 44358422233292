.categories {
    display: flex;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
}

.categories-head {
    margin-right: 50px;
    max-width: 323px;
    width: 100%;
}

.categories-title {
    margin-bottom: 30px;
}

.categories-content {
    display: flex;
    align-items: flex-start;
    height: fit-content;
    flex-wrap: wrap;
}

.categories-checkbox_label {
    text-transform: capitalize;
    cursor: default;
    font-weight: 300;
}

.categories-item {
    background: #272727;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 7px;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    transition: .25s ease;
    cursor: default;
    height: fit-content;
}

.main-form_block,
.main-categories {
    width: 45%;
    margin-left: 2%;
}

.main-form_block--inner {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.main-form {
    margin-right: 8px;
}

.categories-checkbox_block {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.categories-checkbox {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-right: 12px;
    padding: 0;
    background: #272727;
    border: unset;
    box-shadow: inset 0 0 0 1px #949aa4;
    position: relative;
    cursor: pointer;
}

.categories-checkbox:after,
.categories-checkbox:before {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 4px;
    width: 0;
    height: 2px;
    background: #D92499;
    border-radius: 3px;
    transform-origin: center left;
    transition: .2s ease;
}

.categories-checkbox:before {
    transform: rotate(57deg);
    left: 7px;
    bottom: 13px;
    transition-delay: .18s;
}

.categories-checkbox:after {
    transform: rotate(-58deg);
    transition-delay: 0ms;
}

.categories-checkbox:checked {
    background: #fff;
    box-shadow: inset 0 0 0 1px #fff;
    color: #fff;
}

.categories-checkbox:checked:before {
    width: 11px;
    transition-delay: 0ms;
}

.categories-checkbox:checked:after {
    width: 15px;
    transition-delay: .18s;
}

.categories .popup_btn--join {
    /* background: #FFB800; */
    margin-top: 40px;
    /* color: #070707; */
}

/* .categories .popup_btn--join:hover {
    background: #fff;
    color: #070707;
}

.categories .popup_btn--join:hover::after {
    background-image: url('../../../img/btn-arr_black.svg');
}

.categories .popup_btn--join::after {
    background-image: url('../../../img/btn-arr_black.svg');
} */

@media screen and (max-width: 1200px) {
    .categories {
        flex-direction: column;
    }

    .categories-head {
        margin-right: 0;
        max-width: unset;
        width: 100%;
        margin-bottom: 30px;
    }

    .categories-title {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .main-categories_title {
        font-size: 1rem;
        margin-bottom: 20px;
    }

    .categories-item {
        padding: 5px 14px;
    }
}